import React, { useState } from 'react';
import Cell, { FolderCell } from '../Cell/Cell';
import ArchiveItemMenuButton from '@components/photoArchive/common/ArchiveItemMenuButton/ArchiveItemMenuButton';
import { renameFolder } from '@components/photoArchive/common/helpers/folder';
import { ModalParams } from '@components/photoArchive/common/ArchiveItemMenu/ArchiveItemMenu';

import styles from './Row.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import { pushFolderToPath, replcaeElement } from '@store/feature/folder.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { PhotoArchiveApis } from '@APIs/photo-archive.apis';

type RowProps = {
    title: string;
    description: string;
    date: number;
    parent: string;
    id: string;
    patientId: string;
    itemContent: number;
    onDelete: (params: ModalParams) => void;
    order: number;
    type: string;
    style?: {
        bg_color: string;
    };
};

const FolderRow = (props: RowProps): JSX.Element => {
    const [isVisible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const itemContent = Number(props.itemContent);
    const amount = itemContent % 10;
    const contentName = `${itemContent} файл${
        amount > 4 ||
        amount === 0 ||
        itemContent === 11 ||
        itemContent === 12 ||
        itemContent === 13 ||
        itemContent === 14
            ? 'ов'
            : amount != 1
            ? 'а'
            : ''
    }`;

    const editRowTitle = (newTitle: string) => {
        renameFolder(props.id, props.parent, newTitle, props.description);
    };

    const colorChange = (id: string) => async (color: string) => {
        const { data } = await PhotoArchiveApis.updateFolder(id, {
            style: {
                bg_color: color,
            },
        });

        if (data) {
            dispatch(replcaeElement(data));
        }
    };

    const onOpen = () => {
        dispatch(
            pushFolderToPath({
                title: props.title,
                url: props.id,
            })
        );
    };

    return (
        <Link
            className={styles.Row}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            onClick={onOpen}
            to={ROUTES.PATIENTS.$(props.patientId).STORAGE.$(props.id).END}
        >
            <div className={styles.Check}></div>
            <div className={styles.LeftBorder}>{props.order}</div>
            <div className={styles.Name}>
                <FolderCell
                    isEditable={true}
                    isNameCell={true}
                    value={props.title}
                    key={props.type}
                    type={props.type === 'simple' ? 'FOLDER' : 'SMART'}
                    editAction={editRowTitle as (arg: unknown) => void}
                    style={props.style}
                    onColorChange={colorChange(props.id)}
                />
            </div>
            <div className={styles.Date}>
                <Cell
                    isEditable={false}
                    isNameCell={false}
                    value={new Date(props.date)
                        .toISOString()
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('.')}
                    isFocused={false}
                />
            </div>
            <div className={styles.Files}>
                <Cell
                    isEditable={false}
                    isNameCell={false}
                    value={contentName}
                    isFocused={false}
                />
            </div>
            <div className={styles.Other}></div>
            <div className={styles.RightBorder}>
                <div className={styles.ButtonWrapper}>
                    {props.type === 'simple' && (
                        <ArchiveItemMenuButton
                            isVisible={isVisible}
                            itemId={props.id}
                            name={props.title}
                            type="FOLDER"
                            onDelete={props.onDelete}
                        />
                    )}
                </div>
            </div>
        </Link>
    );
};

export default FolderRow;
