import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { photoCollectionAPIs } from '@APIs/photo-collection.apis';
import style from './PresentationsTable.module.scss';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ROUTES } from '@constants/routes';
import { toast } from 'react-toastify';

const PresentationsTablePhotoCollectionColumn = (
    params: GridRenderCellParams & {
        goTo: (route: string) => void;
        dispatch: ThunkDispatch<any, any, any>;
    }
) => {
    const handleClick = () => {
        photoCollectionAPIs
            .getOne(params.row.collection.id, params.row.id)
            .then((res) => {
                if (!res.data.children.length) {
                    toast.error('Не удалось открыть фотоколлекцию');
                    return;
                }
                params.goTo(
                    ROUTES.PATIENTS.$(params.row.patient.id)
                        .PHOTOS.$(res.data.id)
                        .FILL.$(res.data.children[0].id).END
                );
            })
            .catch(() => toast.error('Не удалось открыть фотоколлекцию'));
    };
    return (
        <div className={style.photoCollectionCell} onClick={handleClick}>
            <span className={style.photoCollectionCell__title}>
                {params.row.collection.title ?? 'Неизвестная фотоколлекция'}
            </span>
        </div>
    );
};

export default PresentationsTablePhotoCollectionColumn;
