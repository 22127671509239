import React from 'react';
import styles from './PaymentLink.module.scss';
import { convertCurrencyToString } from '@utils/utils';

const PaymentLink = ({
    title,
    price,
    link,
}: {
    title: string | null;
    price: string | null;
    link: string | null;
}) => (
    <div>
        <div className={styles.ImageContainer}>
            <div className={styles.ImageProtected} />
            <div>
                <h2>Переход на оплату</h2>
                <p>
                    Откроется безопасная страница для оплаты подписки на {title}
                    , ваши данные защищены.
                </p>
                <h6>
                    {convertCurrencyToString(price ?? 0)}₽ за {title}.
                </h6>
                <div className={styles.ButtonPay}>
                    <a className={styles.ButtonTitlePay} href={link ?? ''}>
                        Оплатить
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default PaymentLink;
