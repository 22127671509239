import React, { useCallback, useEffect, useState } from 'react';
import styles from './ProfilePage.module.scss';
import { useForm } from 'react-hook-form';
import { useAuth } from '@contexts/AuthContext';
import { UserPhotoUpload } from '@components/patient/patientinfo/addpatient/userPhotoUpload/UserPhotoUpload';

import { profileAPIs } from '@APIs/profile.apis';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import { Plan } from './plan/Plan';
import dateFormat from 'dateformat';
import { ROUTES } from '@constants/routes';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { AuthorizationApis as AuthAPIs } from '@APIs/auth.apis';
import InputField from '@components/inputfield/InputField';
import { zodResolver } from '@hookform/resolvers/zod';
import { classes } from '@utils/classNames';
import { ProfileFormDataType, validator } from './validate';
import { IUserProfile } from '@interfaces/authentication';
import { AxiosError } from 'axios';
import axios from 'axios';
import { Period } from './plan/Period';
import { Subscription, purchaseAPIs } from '@APIs/purchase';
import Button from '@components/common/NEWDESIGN/Button/Button';

export default function ProfilePage(): JSX.Element {
    const history = useHistory();
    const { profile, setProfile, profilePicture } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [isPromocodeLoading, setIsPromocodeLoading] = useState(false);
    const [promocodeError, setPromocodeError] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File>();

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [selectedSubscription, setSelectedSubscription] =
        useState<Subscription>();

    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(profilePicture);
    const [isPasswordEditing, setIsPasswordEditing] = useState(false);
    const initialUser: ProfileFormDataType = {
        email: profile.email,
        name: profile.name,
        promocode: '',
        surname: profile.surname,
        middleName: profile.middleName,
        degree: profile.degree,
        position: profile.position,
        company: profile.company,
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        watch,
    } = useForm<ProfileFormDataType>({
        mode: 'onChange',
        defaultValues: initialUser,
        resolver: zodResolver(validator(isPasswordEditing).schema),
    });

    const changePassword = useCallback((): void => {
        const currentPassword = getValues('currentPassword');
        const newPassword = getValues('newPassword');

        if (currentPassword && newPassword) {
            profileAPIs
                .changePassword(currentPassword, newPassword)
                .then((response) =>
                    handlePasswordChangeSuccess(response.status)
                )
                .catch((error) => handlePasswordChangeError(error));
        }
    }, [getValues]);

    const handlePasswordChangeSuccess = (responseStatus: number) => {
        if ([200, 204, 304].includes(responseStatus)) {
            clearErrors('currentPassword');
            resetPasswordFields();
            setIsPasswordEditing(false);
            toast.success('Данные успешно обновлены!');
        }
    };

    const handlePasswordChangeError = (error: AxiosError) => {
        setError('currentPassword', {
            type: 'focus',
            message: error.response?.data.message,
        });
    };

    const resetPasswordFields = () => {
        reset({
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
        });
    };

    const updateProfile = ({
        name,
        surname,
        middleName,
        company,
        position,
        degree,
    }: ProfileFormDataType): void => {
        setIsLoading(true);

        profileAPIs
            .updateProfile({
                ...profile,
                name,
                surname,
                middleName,
                company,
                position,
                degree,
            })
            .then((profileResponse) => {
                handleProfileUpdateSuccess(profileResponse.data);
                if (selectedFile) {
                    uploadUserAvatar(selectedFile);
                }
            })
            .catch(() => {
                toast.error('Не удалось обновить данные.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleProfileUpdateSuccess = (profileResponse: IUserProfile) => {
        setProfile(profileResponse);
        toast.success('Профиль обновлён');
    };

    const handleSendPromocode = async () => {
        const promocode = getValues('promocode');
        setIsPromocodeLoading(true);

        try {
            if (promocode) {
                await profileAPIs.setPromocode(promocode);
                toast.success('Промокод добавлен');
                setPromocodeError('');
                const updatedProfile = await AuthAPIs.getProfile();
                setProfile(updatedProfile.data);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setPromocodeError(
                    error.response?.data?.message || 'Произошла ошибка'
                );
            } else {
                setPromocodeError('Произошла ошибка');
            }
        } finally {
            setIsPromocodeLoading(false);
        }
    };

    const uploadUserAvatar = (selectedFile: File) => {
        profileAPIs
            .uploadUserAvatar(selectedFile)
            .then((response) => {
                setProfile({
                    ...profile,
                    avatar: response.data.link,
                });
            })
            .catch(() => {
                toast.error('Ошибка при загрузке фотографии.');
            });
    };

    useEffect(() => setSelectedPhotoUrl(profilePicture), [profilePicture]);

    useEffect(() => {
        const new_pass = watch('newPassword');
        const pass_confirm = watch('newPasswordConfirm');
        if (new_pass !== pass_confirm) {
            setError('newPasswordConfirm', {
                message: 'Пароли не совпадают',
            });
        }
    }, [watch('newPassword'), watch('newPasswordConfirm')]);

    useEffect(() => {
        const getData = async () => {
            const subscriptions = await purchaseAPIs.subscriptions();
            const sorted = subscriptions.data
                .sort((a, b) => {
                    return a.price - b.price;
                })
                .reverse();

            setSubscriptions(sorted);

            const selectedSubscription = sorted.filter(
                (e) => e._id == profile.purchase.subscription
            )[0];
            setSelectedSubscription(selectedSubscription ?? sorted[0]);
        };

        getData();
    }, []);

    return (
        <>
            <ProfileHeader
                isLoading={isLoading}
                handleSubmit={(e) => {
                    const handler = handleSubmit(updateProfile);
                    handler(e);
                }}
            />
            <div className={styles.container}>
                <div className={styles.photo}>
                    <div className={styles.photo_header}>Фотография</div>
                    <UserPhotoUpload
                        setSelectedFile={setSelectedFile}
                        selectedPhotoUrl={selectedPhotoUrl}
                        setSelectedPhotoUrl={setSelectedPhotoUrl}
                    />
                </div>
                <div className={styles.plan}>
                    <Period
                        periods={subscriptions}
                        onTap={(subscription: Subscription) => {
                            setSelectedSubscription(subscription);
                        }}
                        maxPrice={subscriptions[0]?.price ?? 0}
                        active={selectedSubscription?._id ?? ''}
                    />
                    <div className={styles.planList}>
                        <Plan
                            title="Beginner"
                            description="Инновации доступны каждому"
                            price={'0'}
                            maxPrice={0}
                            features={[
                                { title: '1 AI-действие / мес', ai: true },
                                { title: '3 пациента / мес', ai: false },
                                { title: '3 расчета ТРГ / мес', ai: false },
                                { title: '3 презентации / мес', ai: false },
                                { title: '500MB памяти хранилища', ai: false },
                            ]}
                            isActive={!profile.purchase?.status}
                            isButtonDisabled={false}
                            buttonTitle={'Бесплатно'}
                            onTap={console.log}
                        />
                        <div className={styles.plan_subscr}>
                            <Plan
                                title="Pro"
                                description="Используйте платформу без ограничений"
                                maxPrice={subscriptions[0]?.price ?? 0}
                                price={
                                    selectedSubscription?.price.toString() ??
                                    '----'
                                }
                                features={[
                                    { title: '∞ AI-действий', ai: true },
                                    { title: '∞ пациентов', ai: false },
                                    { title: '∞ расчетов ТРГ', ai: false },
                                    { title: '∞ презентаций', ai: false },
                                    {
                                        title: '100GB памяти хранилища',
                                        ai: false,
                                    },
                                ]}
                                isActive={profile.purchase?.status}
                                isButtonDisabled={!profile.purchase?.status}
                                buttonTitle={
                                    profile.purchase?.status
                                        ? 'до ' +
                                          dateFormat(
                                              new Date(profile.purchase.date),
                                              'd mmmm yyyy'
                                          )
                                        : 'Активировать'
                                }
                                onTap={() => {
                                    history.push(
                                        ROUTES.PAYMENT.END +
                                            '/' +
                                            selectedSubscription?._id
                                    );
                                }}
                            />
                        </div>
                        <Plan
                            title="Clinic"
                            description="Внедряйте решения в большую команду"
                            price={'----'}
                            maxPrice={0}
                            features={[
                                { title: 'План PRO', ai: false },
                                { title: '∞ памяти хранилища', ai: false },
                                { title: 'Обучение сотрудников', ai: false },
                                { title: 'Корпоративные темы', ai: false },
                                {
                                    title: 'Индивидуальная поддержка',
                                    ai: false,
                                },
                            ]}
                            isActive={false}
                            isButtonDisabled={true}
                            buttonTitle={'Запросить'}
                            onTap={() =>
                                window.open(
                                    'https://t.me/dilab_suport_bot',
                                    '_blank'
                                )
                            }
                        />
                    </div>
                </div>
                <div className={styles.account_header}>Аккаунт</div>
                <div className={styles.work_info_header}>Работа</div>
                <div className={styles.personal_info_header}>Личные данные</div>
                <div className={styles.email}>
                    <InputField
                        label="Почта"
                        placeholder="Введите свой email-адрес"
                        fieldName="email"
                        type="EMAIL"
                        register={register('email')}
                        errorText={errors.email?.message}
                        isDisabled={true}
                    />
                </div>
                {isPasswordEditing ? (
                    <>
                        <div className={styles.password_message}>
                            Введите свой текущий пароль и новый, чтобы поменять
                            его.
                        </div>
                        <div className={styles.current_password}>
                            <InputField
                                label="Текущий пароль"
                                placeholder="Введите текущий пароль"
                                fieldName="currentPassword"
                                type="PASSWORD"
                                register={register('currentPassword')}
                                errorText={errors.currentPassword?.message}
                                className={styles.passwordInput}
                            />
                        </div>
                        <div className={styles.new_pass}>
                            <InputField
                                label="Новый пароль"
                                placeholder="Введите новый пароль"
                                fieldName="newPassword"
                                type="PASSWORD"
                                register={register('newPassword')}
                                errorText={errors.newPassword?.message}
                            />
                        </div>
                        <div className={styles.new_pass_confirm}>
                            <InputField
                                label="Повторите новый пароль"
                                placeholder="Повторите новый пароль"
                                fieldName="newPasswordConfirm"
                                type="PASSWORD"
                                register={register('newPasswordConfirm')}
                                errorText={errors.newPasswordConfirm?.message}
                            />
                        </div>
                        <button
                            className={classes(styles['password-change__btn'])}
                            disabled={
                                !!Object.keys(errors).length ||
                                !watch('newPassword') ||
                                !watch('newPasswordConfirm')
                            }
                            onClick={changePassword}
                        >
                            Изменить пароль
                        </button>
                    </>
                ) : (
                    <div
                        className={styles.current_password_change}
                        onClick={() => setIsPasswordEditing(true)}
                    >
                        Изменить пароль
                    </div>
                )}

                <div className={styles.surname}>
                    <InputField
                        label="Фамилия"
                        placeholder="Ваша фамилия"
                        fieldName="surname"
                        type="PLAINTEXT"
                        register={register('surname')}
                        errorText={errors.surname?.message}
                    />
                </div>
                <div className={styles.name}>
                    <InputField
                        label="Имя"
                        placeholder="Ваше имя"
                        fieldName="name"
                        type="PLAINTEXT"
                        register={register('name')}
                        errorText={errors.name?.message}
                    />
                </div>
                <div className={styles.middlename}>
                    <InputField
                        label="Отчество"
                        placeholder="Ваше отчество"
                        fieldName="middleName"
                        isRequired={false}
                        type="PLAINTEXT"
                        register={register('middleName', { required: false })}
                        errorText={errors.middleName?.message}
                    />
                </div>
                <div className={styles.company}>
                    <InputField
                        label="Компания"
                        placeholder="В какой компании вы работаете"
                        fieldName="company"
                        isRequired={false}
                        type="PLAINTEXT"
                        register={register('company', { required: false })}
                        errorText={errors.company?.message}
                    />
                </div>
                <div className={styles.position}>
                    <InputField
                        label="Должность"
                        placeholder="Ваша должность"
                        fieldName="position"
                        isRequired={false}
                        type="PLAINTEXT"
                        register={register('position', { required: false })}
                        errorText={errors.position?.message}
                    />
                </div>
                <div className={styles.degree}>
                    <InputField
                        label="Ученая степень"
                        placeholder="Какая у вас степень"
                        fieldName="degree"
                        isRequired={false}
                        type="PLAINTEXT"
                        register={register('degree', { required: false })}
                        errorText={errors.degree?.message}
                    />
                </div>
                <div className={styles.bonus_header}>Бонусы</div>
                <div className={styles.promocode}>
                    <InputField
                        label="Промокод"
                        placeholder="Введите промокод"
                        fieldName="promocode"
                        register={register('promocode')}
                        isRequired={false}
                        type="PLAINTEXT"
                        errorText={promocodeError}
                    />
                    <Button
                        text={'Применить'}
                        size={'SMALL'}
                        padding={'13px 30px'}
                        onClick={handleSendPromocode}
                        isLoading={isPromocodeLoading}
                        isActive={!!watch('promocode')}
                    />
                </div>
            </div>
        </>
    );
}
