import React, { useEffect, useState } from 'react';
import FileRow from './Row/FileRow';
import FolderRow from './Row/FolderRow';
import Dropdown from './Dropdown/Dropdown';
import {
    IFileInLoad,
    ILoadingProgress,
} from '@interfaces/collection.interface';
import { handleDrop, loadFiles } from '../common/helpers/helpers';
import Header from './Header/Header';
import { IArchiveCollection } from '@interfaces/collection.interface';
import LoaderWindow from '../loader/Loader';
import { deleteFiles } from '@components/photoArchive/common/ArchiveItemMenu/actions';
import { useLoader } from '@hooks/useLoader';
import { useLocation } from 'react-router';
import { Modal } from '@components/common/ModalWindow/ModalWindow';

import styles from './Table.module.scss';
import pageButton from '@images/table_page_left.svg';
import PhotoViewModal from '../common/PhotoViewModal/PhotoViewModal';
import { nameComparator, sortFoldersFirst } from '../common/helpers/sorting';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';

type TableProps = {
    patientId: string;
    data: IArchiveCollection[];
    onElementAddition: () => void;
    isInFolder: boolean;
    folderId?: string;
    isLoading: boolean;
};

type ModalParams = {
    modalText: string;
    action: () => void;
};

const PhotoTable = ({
    patientId,
    data,
    onElementAddition,
    isInFolder,
    folderId,
    isLoading,
}: TableProps): JSX.Element => {
    const location = useLocation();
    const loader = useLoader();

    const [amount, setAmount] = useState(100);
    const [page, setPage] = useState(1);
    const [markedRows, setMarked] = useState([] as string[]);
    const [is_chaking, setChecked] = useState(false);

    const [isUploaderShown, setUploaderShown] = useState(false);
    const [filesToLoad, setFilesToLoad] = useState([] as IFileInLoad[]);
    const [loadProgress, setProgress] = useState<ILoadingProgress[]>([]);
    const [sortType, setSortType] = useState(0);

    const [displayData, setDisplayData] = useState([...data]);
    const [allMarked, setAllMarked] = useState(false);
    useEffect(() => {
        setDisplayData(sortFoldersFirst([...data]));
        setSortType(0);
    }, [data]);

    useEffect(() => {
        setAllMarked(false);
        setMarked([]);
    }, [location]);

    const setFilesToUpload = (file: IFileInLoad[]) => {
        setFilesToLoad([...filesToLoad, ...file]);
        setUploaderShown(true);
    };

    const updateLoadProgress = (progressData: ILoadingProgress[]) => {
        setProgress([...loadProgress, ...progressData]);
    };

    const sort = () => {
        const newSortType = (sortType + 1) % 3;
        switch (newSortType) {
            case 1:
                setDisplayData([
                    ...displayData.sort((a, b) => nameComparator(a, b)),
                ]);
                break;
            case 2:
                setDisplayData([
                    ...displayData.sort((a, b) => nameComparator(a, b, -1)),
                ]);
                break;
            default:
                setDisplayData(sortFoldersFirst([...data]));
        }
        setSortType(newSortType);
    };

    const setLinesPerPageAmount = (newAmount: number) => {
        setPage(1);
        setAmount(newAmount);
    };

    const markAll = () => {
        if (allMarked) clearMarks();
        else {
            setMarked(
                data
                    .filter((el) => el.type === 'FILE')
                    .map((el) => {
                        return el.id;
                    })
            );
        }
        setAllMarked(!allMarked);
        setChecked(markedRows.length > 0);
    };

    const markRow = (id: string) => {
        setMarked((curr) => {
            if (curr.includes(id)) {
                const next = curr.filter((item) => item !== id);
                setChecked(next.length > 0);
                return next;
            } else {
                const next = [...curr, id];
                setChecked(next.length > 0);
                return next;
            }
        });
    };

    const clearMarks = () => {
        setMarked([]);
        setChecked(false);
    };

    const [openedFileIndex, setOpenedIndex] = useState<number>(-1);
    const filesOnlyData = data.filter((val) => val.type === 'FILE');

    const switchFile = (dir: number) => {
        if (
            openedFileIndex !== -1 &&
            openedFileIndex + dir >= 0 &&
            openedFileIndex + dir < filesOnlyData.length
        ) {
            setOpenedIndex(openedFileIndex + dir);
        }
    };

    const openFileView = (id: string) => {
        const currentIndex = filesOnlyData.findIndex((val) => val.id === id);
        setOpenedIndex(currentIndex);
    };

    const closeFileView = () => {
        setOpenedIndex(-1);
    };

    const download = () => {
        markedRows.map((id) => {
            const fileData = data.find((el) => el.id === id);
            if (fileData) {
                loader.download(fileData.full);
            }
        });

        clearMarks();
    };

    const remove = () => {
        if (markedRows.length === 1) {
            const [id] = markedRows;
            const fileData = data.find((el) => el.id === id);
            const name = decodeURI(fileData?.name ?? '');
            setModalParams({
                modalText: `Вы действительно хотите удалить ${name}?`,
                action: () => deleteFiles(markedRows),
            });
        } else {
            setModalParams({
                modalText:
                    'Вы действительно хотите удалить выбранные фотографии?',
                action: () => deleteFiles(markedRows),
            });
        }

        clearMarks();
    };

    const cancel = () => {
        setMarked([]);
        setAllMarked(false);
        setChecked(false);
    };

    const stopEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const drop = (e: React.DragEvent<HTMLDivElement>) => {
        isInFolder &&
            loadFiles(
                patientId,
                String(folderId),
                handleDrop(e),
                onElementAddition,
                setFilesToUpload,
                updateLoadProgress
            );
    };

    const [modalParams, setModalParams] = useState<ModalParams | null>(null);

    return !isLoading ? (
        <CustomLoader />
    ) : (
        <>
            <div className={styles.TableWrapper}>
                <div className={styles.TableMain}>
                    <Header
                        patientId={patientId}
                        isInFolder={isInFolder}
                        folderId={folderId}
                        markedAll={allMarked}
                        markAll={markAll}
                        onCancel={cancel}
                        onDelete={remove}
                        onDownload={download}
                        isChecking={is_chaking}
                        onElementAddition={onElementAddition}
                        onLoadFile={setFilesToUpload}
                        onLoadProgress={updateLoadProgress}
                        sortType={sortType}
                        sort={sort}
                    />
                    <div
                        className={styles.TableBody}
                        onDragOver={stopEvent}
                        onDragEnter={stopEvent}
                        onDragLeave={stopEvent}
                        onDrop={drop}
                    >
                        {displayData.length === 0 ? (
                            <div className={styles.Empty}>
                                Список хранилища пуст
                            </div>
                        ) : (
                            displayData.map((el, ind, displayData) => {
                                if (
                                    ind >= (page - 1) * amount &&
                                    ind < page * amount
                                )
                                    return el.type === 'FILE' ? (
                                        <FileRow
                                            onClick={openFileView}
                                            key={el.id}
                                            name={el.name}
                                            id={el.id}
                                            date={
                                                el.photo.timeCreated
                                                    ? el.photo.timeCreated
                                                    : 0
                                            }
                                            itemContent={el.name.substring(
                                                el.name.lastIndexOf('.') + 1
                                            )}
                                            preview={el.preview}
                                            full={el.full}
                                            isChecked={markedRows.includes(
                                                el.id
                                            )}
                                            markRow={markRow}
                                            onDelete={setModalParams}
                                        />
                                    ) : (
                                        <FolderRow
                                            key={el.id}
                                            type={el.key}
                                            title={el.title}
                                            parent={el.parent}
                                            description={el.description}
                                            id={el.id}
                                            patientId={patientId}
                                            style={el.style}
                                            date={
                                                el.updated_at
                                                    ? el.updated_at
                                                    : 0
                                            }
                                            itemContent={el.children.length}
                                            onDelete={setModalParams}
                                            order={
                                                displayData
                                                    .filter(
                                                        (el) =>
                                                            el.type !== 'FILE'
                                                    )
                                                    .indexOf(el) + 1
                                            }
                                        />
                                    );
                            })
                        )}
                    </div>
                </div>
                <div className={styles.TableFooter}>
                    <div className={styles.ButtonWrapper}>
                        <Dropdown
                            title="Строк на странице"
                            options={[25, 50, 100]}
                            defaultOption={2}
                            setOption={setLinesPerPageAmount}
                        />
                        <div className={styles.PageNumber}>
                            {(page - 1) * amount}-
                            {Math.min(page * amount, data.length)} из{' '}
                            {data.length}
                        </div>
                        <div className={styles.SwitchPage}>
                            <img
                                alt="Back"
                                src={pageButton}
                                className={styles.Back}
                            />
                            <img
                                alt="Forward"
                                src={pageButton}
                                className={styles.Forward}
                            />
                        </div>
                    </div>
                    {isUploaderShown && (
                        <LoaderWindow
                            files={filesToLoad}
                            progress={loadProgress}
                            onClose={() => {
                                setUploaderShown(false);
                                setFilesToLoad([]);
                            }}
                        />
                    )}
                </div>
            </div>
            {openedFileIndex !== -1 && (
                <PhotoViewModal
                    src={filesOnlyData[openedFileIndex].full}
                    title={filesOnlyData[openedFileIndex].name}
                    onClose={closeFileView}
                    onSwitch={switchFile}
                    hasPrev={openedFileIndex - 1 >= 0}
                    hasNext={openedFileIndex + 1 < filesOnlyData.length}
                />
            )}
            {modalParams && (
                <Modal
                    message={modalParams.modalText}
                    isOpen={modalParams !== null}
                    onClose={() => setModalParams(null)}
                    btnLabel="Удалить"
                    btnAction={() => {
                        modalParams.action();
                        setModalParams(null);
                    }}
                />
            )}
        </>
    );
};

export default PhotoTable;
