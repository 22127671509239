import { Select } from '@material-ui/core';
import { MenuItem } from '@mui/material';

import React, { useCallback, useMemo, useState } from 'react';
import styles from './PresentationsTable.module.scss';

export const StatusCell = (
    status: 'IN_PROCESS' | 'COMPLETED' | 'InProgress' | 'Done',
    changeStatus: (newStatus: any) => void
): JSX.Element => {
    const [currentState, setCurrentState] = useState<string>(status);
    const [status_list] = useState<{ [key: string]: string }>(
        ['InProgress', 'Done'].includes(status)
            ? {
                  InProgress: 'В работе',
                  Done: 'Готово',
              }
            : {
                  IN_PROCESS: 'В работе',
                  COMPLETED: 'Готово',
              }
    );

    const [backgroundColor, setBGColor] = useState<string>(
        {
            InProgress: '#FFF9DB',
            Done: '#ECFFEE',
            IN_PROCESS: '#FFF9DB',
            COMPLETED: '#ECFFEE',
        }[status] ?? '#FFF9DB'
    );

    const onStatusChange = useCallback(
        (state: string) => {
            changeStatus(state);
            setCurrentState(state);
            setBGColor(
                {
                    InProgress: '#FFF9DB',
                    Done: '#ECFFEE',
                    IN_PROCESS: '#FFF9DB',
                    COMPLETED: '#ECFFEE',
                }[state] ?? '#FFF9DB'
            );
        },
        [currentState]
    );

    const isActive = useMemo(() => {
        return currentState === 'Done' || currentState === 'COMPLETED';
    }, [currentState]);

    return (
        <Select
            className={
                isActive ? styles.status_cell_active : styles.status_cell
            }
            defaultValue={currentState}
            onChange={(e) => {
                e.stopPropagation();
                onStatusChange(e.target.value as string);
            }}
            style={{ backgroundColor, fontSize: 12, fontWeight: 400 }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            disableUnderline
            displayEmpty
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            classes={{
                icon: styles.status_cell_dropdown,
                iconOpen: styles.status_cell_dropdown_active,
            }}
        >
            {Object.entries(status_list).map(([key, value]) => (
                <MenuItem
                    key={key}
                    value={key}
                    className={styles.status_cell_change_title}
                    style={{
                        fontSize: 12,
                    }}
                >
                    {value}
                </MenuItem>
            ))}
        </Select>
    );
};
