import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PatientsPage.scss';

import { ROUTES } from '@constants/routes';

import PatientsBody from './body/PatientsBody';
import {
    selectPatients,
    selectPatientsIsRestricted,
} from '@store/feature/patients.slice';
import { SearchComponent } from '@components/common/search-component/search-component';
import { useAppSelector } from '@hooks/useAppSelector';
import { IPatient } from '@interfaces/patient.interface';
import { Alert } from '@components/common/alert/Alert';
import LoadingIndicator from '../patientinfo/LoadingIndicator';

const PatientsPage = (): JSX.Element => {
    const patients = useAppSelector(selectPatients);
    const patientsIsRestricted = useAppSelector(selectPatientsIsRestricted);
    const [filteredPatients, setFilteredPatients] = useState<IPatient[]>([]);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value && e.target.value.trim()) {
                setFilteredPatients(
                    patients.filter(({ middleName, name, surname }) =>
                        `${name + surname + middleName}`
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                );
            } else {
                setFilteredPatients(patients);
            }
        },
        [patients]
    );

    useEffect(() => {
        if (patients.length > 0) {
            const reversePatients = [...patients].reverse();
            setFilteredPatients(reversePatients);
        } else {
            setFilteredPatients([]);
        }
    }, [patients]);

    return (
        <div className="user_list_container_root">
            <div className="user_list_header_container">
                <div className="user_list_title-container">
                    <p className="user_list_title">Все пациенты</p>
                </div>
                <div className="user_list_buttons-container">
                    <SearchComponent onChange={onChange} />
                    <Link
                        to={ROUTES.PATIENTS.ADD.END}
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="user_list_add_button_container">
                            <div className="user_list_add_button">
                                <div className="user_list_add_image_add" />
                                <p className="user_list_add_title">Добавить</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <LoadingIndicator isLoading={false} />
            <Alert
                title="Ваши пациенты"
                description="Здесь вы можете просматривать всех пациентов, а также создавать новых. Нажмите на строчку или имя, чтобы перейти к пациенту для создания фотоколлекций, презентаций или расчетов."
                id="all_patient"
            />
            <PatientsBody
                patients={filteredPatients}
                isRestricted={patientsIsRestricted}
            />
        </div>
    );
};

export default PatientsPage;
