import React, { useState } from 'react';
import './addFolderMenuItem.scss';
import magicFolder from '@assets/images/magic_folder.svg';

const AddFolderMenuItem = ({
    name,
    isMagic = false,
    clickFunction,
}: {
    name: string;
    isMagic?: boolean;
    clickFunction: () => void;
}): JSX.Element => {
    return (
        <div className="addFolderMenuItem" onClick={clickFunction}>
            <h4 className="addFolderMenuItem__title">{name}</h4>
            <div className="addFolerMenuItem__icon">
                {isMagic ? <img src={magicFolder} /> : null}
            </div>
        </div>
    );
};

export default AddFolderMenuItem;
