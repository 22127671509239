import React from 'react';
import icon from '@assets/images/addFolderIcon.svg';
import './addButton.scss';

export default function AddButton({
    onClick,
}: {
    onClick?: () => void;
}): JSX.Element {
    return (
        <a className="addNewFolderButton" onClick={onClick}>
            <img src={icon} alt="" className="addNewFolderButton__image" />
            <span className="addNewFolderButton__text">Добавить</span>
        </a>
    );
}
