import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import TableEditInput from '@components/common/tableEditInput/TableEditInput';
import { presentationAPIs } from '@APIs/presentation.apis';

interface CustomCellParams {
    changeState: (id: string, title: string) => void;
}

const PresentationsTableTitleColumn = (
    params: GridRenderCellParams & CustomCellParams
) => {
    return (
        <TableEditInput
            value={params.row.name}
            onAcceptIconClick={(title: string) => {
                presentationAPIs
                    .updatePresentation(params.row.id, title)
                    .then((res) => {
                        params.changeState(res.data._id, res.data.name);
                    })
                    .catch((error) => console.error(error));
            }}
        />
    );
};

export default PresentationsTableTitleColumn;
