import React, { useRef, useState } from 'react';
import FileRow from './FileRow/FileRow';
import {
    IFileInLoad,
    ILoadingProgress,
} from '@interfaces/collection.interface';
import { cancelLoad } from '../common/helpers/helpers';

import styles from './Loader.module.scss';
import Close from '@images/close_white.svg';
import More from '@images/loader_dots.svg';

type LoaderProps = {
    files: IFileInLoad[];
    progress: ILoadingProgress[];
    onClose: () => void;
};

const LoaderWindow = ({
    files,
    progress,
    onClose,
}: LoaderProps): JSX.Element => {
    const finalData = [] as IFileInLoad[];
    files.map((el) => {
        finalData.push({
            ...el,
            ...progress.find((item) => item.id === el.id),
        });
    });
    const noErrorData = finalData.filter((el) => el.status !== 'ERROR');
    const loadingProgress = Math.floor(
        noErrorData.reduce((partialSum, a) => partialSum + a.progress, 0) /
            noErrorData.length
    );
    const filesInLoad = finalData.filter(
        (el) => el.status === 'LOADING'
    ).length;

    const [isMenuShown, setMenu] = useState(false);
    let menuRight = 0;
    let menuTop = 0;
    const cancelButtonRef = useRef<HTMLDivElement>(null);
    const buttonElement = cancelButtonRef.current;
    if (buttonElement) {
        menuRight = -buttonElement.clientWidth;
        menuTop = buttonElement.clientHeight;
    }

    const fillerStyles = {
        width: `${loadingProgress}%`,
    } as React.CSSProperties;
    return (
        <div className={styles.Wrapper}>
            <div className={styles.Header}>
                <div className={styles.HeaderContent}>
                    {noErrorData.length === finalData.length
                        ? loadingProgress === 100
                            ? 'Загрузка завершена'
                            : `Загружено ${loadingProgress}%`
                        : 'Ошибка загрузки'}
                    {loadingProgress === 100 || filesInLoad === 0 ? (
                        <img
                            src={Close}
                            alt=""
                            className={styles.Close}
                            onClick={onClose}
                        />
                    ) : (
                        <div ref={cancelButtonRef} className={styles.CancelRef}>
                            <img
                                src={More}
                                alt=""
                                className={styles.Cancel}
                                onClick={() => setMenu(!isMenuShown)}
                            />
                            {isMenuShown && (
                                <div
                                    style={{ right: menuRight, top: menuTop }}
                                    className={styles.CancelMenu}
                                    onClick={() => {
                                        cancelLoad(noErrorData);
                                        setMenu(false);
                                    }}
                                >
                                    Отмена загрузки
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.Filler} style={fillerStyles}></div>
            </div>
            <div className={styles.Body}>
                {finalData.map((item) => {
                    return <FileRow data={item} key={item.id} />;
                })}
            </div>
        </div>
    );
};

export default LoaderWindow;
