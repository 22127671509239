import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import './photoArchive.scss';
import { Breadcrumbs } from './breadcrumbs/Breadcrumbs';

import { IArchiveCollection } from '@interfaces/collection.interface';
import PhotoTable from './table/Table';
import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { getPath } from './breadcrumbs/helpers';
import {
    clearPath,
    selectPath,
    setCompletePath,
    setFolderElements,
    popFromPath,
    clearFolder,
} from '@store/feature/folder.slice';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import {
    createdTimeComparator,
    sortFoldersFirst,
} from './common/helpers/sorting';

const PhotoArchive = (): JSX.Element => {
    const [isMounted, setIsMounted] = useState(false);
    const crumbs = useAppSelector(selectPath);
    const [crumbsReady, setCrumbsReady] = useState(false);

    const { folderId, patientId } = useParams() as unknown as {
        folderId: string;
        patientId: string;
    };
    const [folders, setFolders] = useState<IArchiveCollection[]>([]);
    const [currentFolder, setFolder] = useState<IArchiveCollection>();
    const [title, setTitle] = useState('Хранилище');
    const location = useLocation();

    const dispatch = useAppDispatch();
    useEffect(() => {
        setTitle('Хранилище');
        fetchData();
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, [location]);

    const fetchData = () => {
        if (folderId) {
            PhotoArchiveApis.getFolder(folderId).then((res) => {
                setFolder(res.data);
                setTitle(res.data.title);
                const data = res.data
                    .children as unknown as IArchiveCollection[];
                data.sort(createdTimeComparator);
                setFolders(data);
                dispatch(setFolderElements(data));
                if (crumbs.path.length === 1) {
                    if (res.data.parent !== null) {
                        getPath(res.data.parent, patientId, [
                            { title: res.data.title, url: folderId },
                        ]).then((res) => {
                            res.push({
                                title: 'Хранилище',
                                url: '../storage',
                            });
                            dispatch(setCompletePath(res.reverse()));
                            setCrumbsReady(true);
                        });
                    } else {
                        dispatch(
                            setCompletePath([
                                {
                                    title: 'Хранилище',
                                    url: '../storage',
                                },
                                {
                                    title: res.data.title,
                                    url: res.data.id,
                                },
                            ])
                        );
                        setCrumbsReady(true);
                    }
                } else {
                    setCrumbsReady(true);
                }
            });
        } else {
            PhotoArchiveApis.getFolders(patientId).then((res) => {
                setFolders([...res.data.sort(createdTimeComparator)]);
                dispatch(clearPath());
                dispatch(setFolderElements(res.data));
                setCrumbsReady(true);
            });
        }
    };

    const history = useHistory();

    return (
        <>
            <>
                <div className="photoArchive__breadcrumps">
                    {folderId && (
                        <Breadcrumbs path={crumbs} isReady={crumbsReady} />
                    )}
                </div>
                <div className="photoArchive__header">
                    <div className="header__container">
                        {folderId ? (
                            <div
                                className="return_button"
                                onClick={() => {
                                    history.goBack();
                                    dispatch(popFromPath());
                                    dispatch(clearFolder());
                                }}
                            ></div>
                        ) : (
                            ''
                        )}
                        <h3 className="header-title">{title}</h3>
                        {folderId && currentFolder ? (
                            <span className="header-date">
                                {currentFolder.updated_at
                                    ? new Date(currentFolder.updated_at)
                                          .toISOString()
                                          .split('T')[0]
                                          .split('-')
                                          .reverse()
                                          .join('.')
                                    : ''}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="photoArchive">
                    <div className="container">
                        <div className="photoArchive__table">
                            <PhotoTable
                                isLoading={isMounted}
                                patientId={patientId}
                                data={sortFoldersFirst(crumbs.elements)}
                                isInFolder={folderId !== undefined}
                                folderId={folderId}
                                onElementAddition={fetchData}
                            />
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default PhotoArchive;
