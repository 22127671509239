import React from 'react';

import './PatientInfoHeaderMenu.scss';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

export interface Item {
    selected: boolean;
    title: string;
    path: string;
}

type PatientInfoHeaderMenuProps = {
    items: Item[];
};

const PatientInfoHeaderMenu = ({
    items,
}: PatientInfoHeaderMenuProps): JSX.Element => {
    const { url } = useRouteMatch();

    return (
        <div className="user_menu_root">
            {items.map((item, index) => (
                <NavLink
                    to={url + item.path}
                    className="user_menu_title user_menu_link"
                    activeClassName="user_menu_separator_active"
                    key={index}
                >
                    <div>{item.title}</div>
                </NavLink>
            ))}
        </div>
    );
};

export default PatientInfoHeaderMenu;
