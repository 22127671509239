import { IArchiveCollection } from '@interfaces/collection.interface';

export function createdTimeComparator(
    a: IArchiveCollection,
    b: IArchiveCollection,
    order = 1
): number {
    let dateA, dateB;
    if (a.type === 'FILE') {
        dateA = a.created_at;
    } else {
        dateA = a.created_at;
    }
    if (b.type === 'FILE') {
        dateB = b.created_at;
    } else {
        dateB = b.created_at;
    }
    return order * (dateB - dateA);
}

export function nameComparator(
    a: IArchiveCollection,
    b: IArchiveCollection,
    order = 1
): number {
    if (a.type === 'FOLDER' && b.type === 'FILE') return -1;
    if (b.type === 'FOLDER' && a.type === 'FILE') return 1;
    let elementAName, elementBName;
    if (a.type === 'FILE') {
        elementAName = a.name;
    } else {
        elementAName = a.title;
    }
    if (b.type === 'FILE') {
        elementBName = b.name;
    } else {
        elementBName = b.title;
    }
    return order * elementAName.localeCompare(elementBName);
}

export function sortFoldersFirst(
    data: IArchiveCollection[]
): IArchiveCollection[] {
    const newData = [...data];

    const folders = data.filter(({ type }) => type === 'FOLDER');
    const files = data.filter(({ type }) => type === 'FILE');

    const simple_folders = folders.filter(({ key }) => key === 'simple');

    simple_folders.sort((a, b) => b.created_at - a.created_at);
    const smart_folders = folders.filter(
        (item) => !simple_folders.includes(item)
    );

    smart_folders.sort((a, b) => b.created_at - a.created_at);

    files.sort((a, b) => b.created_at - a.created_at);

    return [...smart_folders, ...simple_folders, ...files];
}
