import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { IPathPart } from '@store/feature/folder.slice';

export async function getPath(
    folderId: string,
    patientId: string,
    path: IPathPart[]
): Promise<IPathPart[]> {
    return PhotoArchiveApis.getFolder(folderId).then((res) => {
        path.push({
            title: res.data.title,
            url: folderId,
        });
        if (res.data.parent) {
            return getPath(res.data.parent, patientId, path);
        } else {
            return path;
        }
    });
}
