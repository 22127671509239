import { useAppDispatch } from '@hooks/useAppDispatch';
import {
    IFolderPath,
    removePartsFromPath,
    selectPath,
} from '@store/feature/folder.slice';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';

type PathItem = {
    path: string;
    title: string;
};

export const Breadcrumbs = ({
    path,
    isReady,
}: {
    path: IFolderPath;
    isReady: boolean;
}): JSX.Element => {
    const [isMiddleShown, setShown] = useState(true);
    const [middleParts, setMiddleParts] = useState(
        path.path.length > 2 ? path.path.length - 2 : 0
    );
    const middlePart = useRef<HTMLDivElement>(null);
    const location = useLocation();

    useEffect(
        () => setMiddleParts(path.path.length > 2 ? path.path.length - 2 : 0),
        [path]
    );

    useEffect(() => {
        setShown(true);
        setMiddleParts(path.path.length > 2 ? path.path.length - 2 : 0);
    }, [location]);

    useEffect(() => {
        if (middlePart.current) {
            if (middlePart.current.clientHeight > 26) {
                setMiddleParts(middleParts - 1);
                setShown(false);
            }
        }
    }, [middlePart.current?.clientHeight]);
    const dispatch = useAppDispatch();
    return (
        <div className={styles.Container}>
            {isReady ? (
                <>
                    <Link className={styles.Crumb} to={path.path[0].url}>
                        {path.path[0].title}
                    </Link>
                    <div ref={middlePart}>
                        {path.path.length > 2 ? (
                            isMiddleShown ? (
                                <div className={styles.OptionalPart}>
                                    {path.path.map((el, ind) => {
                                        return (
                                            ind >
                                                0 +
                                                    middleParts -
                                                    path.path.length +
                                                    2 &&
                                            ind < path.path.length - 2 && (
                                                <Link
                                                    key={el.url}
                                                    className={styles.Crumb}
                                                    to={el.url}
                                                    onClick={() => {
                                                        dispatch(
                                                            removePartsFromPath(
                                                                ind
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {el.title}
                                                </Link>
                                            )
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className={styles.Crumb}>...</div>
                            )
                        ) : (
                            ''
                        )}
                    </div>
                    {path.path.length > 2 && (
                        <Link
                            className={styles.Crumb}
                            to={path.path[path.path.length - 2].url}
                            onClick={() => {
                                dispatch(
                                    removePartsFromPath(path.path.length - 2)
                                );
                            }}
                        >
                            {path.path[path.path.length - 2].title}
                        </Link>
                    )}
                </>
            ) : (
                <div className={styles.Crumb}>Загрузка...</div>
            )}
        </div>
    );
};
