import React, { useState } from 'react';

import checkmark from '@images/check-mark_white.svg';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
    isChecked: boolean;
    isShown: boolean;
    markRow: () => void;
};

const Checkbox = (props: CheckboxProps): JSX.Element => {
    return (
        <div
            className={`${styles.FakeCheckbox} ${
                props.isChecked ? styles.Checked : ''
            } ${props.isShown ? styles.Shown : ''}`}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                props.markRow();
            }}
        >
            {props.isChecked ? (
                <img className={styles.Checkmark} src={checkmark} />
            ) : (
                ''
            )}
        </div>
    );
};

export default Checkbox;
