import React from 'react';
import { IPatient } from '@interfaces/patient.interface';
import { DataGrid, GridFooter, ruRU } from '@mui/x-data-grid';
import { PatientsTableColumns } from './PatientsTableColumns';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { ROUTES } from '@constants/routes';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectPatientsState,
    deletePatient,
} from '@store/feature/patients.slice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import TableSubscriptionAlert from '@components/tableSubscriptionAlert/TableSubscriptionAlert';
import '@styles/DataGrid.scss';
import { patientsFake } from '@constants/tablesData';

const PatientsBody = ({
    patients,
    isRestricted,
}: {
    patients: IPatient[];
    isRestricted: boolean;
}): JSX.Element => {
    const Container = styled.div`
        display: flex;
        height: 100%;
        width: 100%;
        background-color: white;
    `;
    const isLoadingPatients = useAppSelector(selectPatientsState);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const deletePatientFromTable = (id: string) => {
        dispatch(deletePatient({ id: id }));
    };

    return (
        <Container>
            <DataGrid
                className={isRestricted ? 'DataGridCustom' : ''}
                components={{
                    NoRowsOverlay: () => {
                        return (
                            <div className="grid_no-rows-overlay">
                                Добавьте пациента
                            </div>
                        );
                    },
                    Footer: () => {
                        return (
                            <div>
                                {isRestricted && (
                                    <TableSubscriptionAlert type={'patients'} />
                                )}
                                <GridFooter />
                            </div>
                        );
                    },
                }}
                columns={PatientsTableColumns}
                rows={patients
                    .concat(isRestricted ? patientsFake : [])
                    .map((p) => ({
                        id: p.id,
                        data: {
                            ...p,
                            patient: p.surname + ' ' + p.name,
                        },
                        deleteFunction: deletePatientFromTable,
                    }))}
                loading={isLoadingPatients}
                hideFooterSelectedRowCount={true}
                onRowClick={(params) =>
                    history.push(
                        ROUTES.PATIENTS.$(`${params.id}`).PRESENTATIONS.END
                    )
                }
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </Container>
    );
};

export default PatientsBody;
