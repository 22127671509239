import React, { useCallback, useEffect, useState } from 'react';
import PresentationsTable from './common/PresentationsTable';
import styles from './PresentationsTablePage.module.scss';
import { useAppSelector } from '@hooks/useAppSelector';
import {
    selectPresentations,
    selectPresentationsIsRestricted,
    selectPresentationsStatus,
} from '@feature/presentations.slice';
import { SearchComponent } from '@components/common/search-component/search-component';
import { Alert } from '@components/common/alert/Alert';
import { IPresentation } from '@interfaces/presentation.interface';
import LoadingIndicator from '@components/patient/patientinfo/LoadingIndicator';

const PresentationState: { [key: string]: string } = {
    InProgress: 'В работу',
    Done: 'Опубликовать',
};

export const PresentationsTablePage = (): JSX.Element => {
    const presentations = useAppSelector(selectPresentations);
    const isRestricted = useAppSelector(selectPresentationsIsRestricted);
    const isLoading = useAppSelector(selectPresentationsStatus);
    const [filteredPresentations, setFilteredPresentations] =
        useState(presentations);

    useEffect(() => {
        setFilteredPresentations(presentations);
    }, [presentations]);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredPresentations(
                e.target.value && e.target.value.trim()
                    ? presentations.filter(
                          ({ patient, name, state }) =>
                              patient &&
                              `${patient.surname} ${patient.name} ${name} ${PresentationState[state]}`
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                      )
                    : presentations
            );
        },
        [presentations]
    );

    function sortByDateComparator(
        first_presentation: IPresentation,
        second_presentation: IPresentation
    ) {
        return second_presentation.date - first_presentation.date;
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>Все презентации</p>
                <SearchComponent onChange={onChange} />
            </div>
            <LoadingIndicator isLoading={false} />
            <Alert
                title="Все презентации"
                description="Это список всех презентаций. Вы можете перейти на пациента или изменить статус презентации. Пациент увидит презентации только в статусе 'Готово'"
                id="all_presentations"
            />
            <PresentationsTable
                presentations={[...filteredPresentations].sort(
                    sortByDateComparator
                )}
                isLoading={isLoading}
                isGlobal={true}
                isRestricted={isRestricted}
            />
        </div>
    );
};

export default PresentationsTablePage;
