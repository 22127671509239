import React, { useRef, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import AddFolderMenu from '@components/photoArchive/common/AddFolderMenu/addFolderMenu';
import AddButton from '@components/photoArchive/common/addButton/addButton';
import {
    IFileInLoad,
    ILoadingProgress,
} from '@interfaces/collection.interface';

import arrow from '@images/arrow_up.svg';
import styles from './Header.module.scss';

type HeaderProps = {
    isInFolder: boolean;
    patientId: string;
    folderId?: string;
    markedAll: boolean;
    markAll: () => void;
    onCancel: () => void;
    onDelete: () => void;
    onDownload: () => void;
    isChecking: boolean;

    sortType: number;
    sort: () => void;
    onElementAddition: () => void;
    onLoadFile: (file: IFileInLoad[]) => void;
    onLoadProgress: (data: ILoadingProgress[]) => void;
};

const Header = ({
    isInFolder,
    patientId,
    folderId,
    markedAll,
    markAll,
    onCancel,
    onDelete,
    onDownload,
    isChecking,
    sortType,
    sort,
    onElementAddition,
    onLoadFile,
    onLoadProgress,
}: HeaderProps): JSX.Element => {
    const [isAddFolderMenuShow, setIsAddFolderMenuShow] = useState(false);
    let menuLeft = 0;
    let menuTop = 0;

    const addButtonRef = useRef<HTMLDivElement>(null);
    const buttonElement = addButtonRef.current;
    if (buttonElement) {
        menuLeft = -buttonElement.clientWidth;
        menuTop = buttonElement.clientHeight;
    }
    const sortStyles = ['', styles.SortedAsc, styles.SortedDesc];
    return (
        <div className={styles.Header}>
            <div className={styles.Check}>
                <Checkbox
                    isChecked={markedAll}
                    isShown={isChecking}
                    markRow={markAll}
                />
            </div>
            <div className={styles.Name}>
                Название
                <img
                    src={arrow}
                    className={`${styles.Arrow} ${sortStyles[sortType]}`}
                    onClick={sort}
                />
            </div>
            <div className={styles.Date}>Дата</div>
            <div className={styles.Files}>Файлы</div>
            <div className={styles.Other}>
                <div
                    ref={addButtonRef}
                    className={styles.AddButtonPlacement}
                    onMouseLeave={() => setIsAddFolderMenuShow(false)}
                >
                    {isChecking ? (
                        <div className={styles.CheckOptions}>
                            <button
                                className={styles.Button}
                                onClick={onDownload}
                            >
                                Скачать
                            </button>
                            <button
                                className={styles.Button}
                                onClick={onDelete}
                            >
                                Удалить
                            </button>
                            <button
                                className={`${styles.Button} ${styles.Cancel}`}
                                onClick={onCancel}
                            >
                                Отменить
                            </button>
                        </div>
                    ) : (
                        <div
                            ref={addButtonRef}
                            className={styles.AddButtonPlacement}
                            onMouseLeave={() => setIsAddFolderMenuShow(false)}
                        >
                            <AddButton
                                onClick={() =>
                                    setIsAddFolderMenuShow(!isAddFolderMenuShow)
                                }
                            />
                            {isAddFolderMenuShow ? (
                                <AddFolderMenu
                                    position={{ left: menuLeft, top: menuTop }}
                                    isInFolder={isInFolder}
                                    patientId={patientId}
                                    folderId={folderId}
                                    onElementAddition={onElementAddition}
                                    onLoadFile={onLoadFile}
                                    onLoadProgress={onLoadProgress}
                                />
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.Add}></div>
        </div>
    );
};

export default Header;
