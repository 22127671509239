import React from 'react';

import styles from './PatientSexField.module.scss';
import { Sex } from '@models/sex.enum';
import { classes } from '@utils/classNames';

type PatientSexFieldProps = {
    sex: Sex;
    onChange: (value: Sex) => void;
};

const PatientSexField = ({
    sex,
    onChange,
}: PatientSexFieldProps): JSX.Element => {
    return (
        <div className={styles.sexField}>
            <label className={styles.sexField__title}>Пол</label>
            <div className={styles.sexField__buttons}>
                <div
                    className={classes(styles.sexField__button, {
                        [styles.sexField__button_selected]: sex === Sex.W,
                    })}
                    onClick={() => onChange(Sex.W)}
                >
                    <div
                        className={classes(styles.sexField__femaleButtonImage, {
                            [styles.sexField__femaleButtonImage_selected]:
                                sex === Sex.W,
                        })}
                    />
                </div>
                <div
                    className={classes(styles.sexField__button, {
                        [styles.sexField__button_selected]: sex === Sex.M,
                    })}
                    onClick={() => onChange(Sex.M)}
                >
                    <div
                        className={classes(styles.sexField__maleButtonImage, {
                            [styles.sexField__maleButtonImage_selected]:
                                sex === Sex.M,
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatientSexField;
