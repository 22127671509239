import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export function renameFolder(
    folderId: string,
    parentId: string,
    title: string,
    description: string
): void {
    PhotoArchiveApis.renameFolder(folderId, parentId, description, title).catch(
        (error: AxiosError) => {
            if (error.response) {
                toast.error(error.response.data.message);
            }
        }
    );
}
