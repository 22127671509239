import React from 'react';
import actions from './actions';
import './ArchiveItemMenu.scss';
import { useLoader } from '@hooks/useLoader';

type ItemMenuProps = {
    itemId: string;
    position: {
        left: number;
        top: number;
    };
    type: string;
    name: string;
    fileUrl?: string;
    onDelete: (params: ModalParams) => void;
};

export type ModalParams = {
    modalText: string;
    action: () => void;
};

const ArchiveItemMenu = ({
    itemId,
    position,
    type,
    name,
    fileUrl,
    onDelete,
}: ItemMenuProps): JSX.Element => {
    const loader = useLoader();
    return (
        <div className="archiveItemMenu" style={position}>
            {actions.map((action) => {
                return action.types.indexOf(type) !== -1 ? (
                    <div
                        className="archiveItemMenu__action"
                        onClick={() => {
                            onDelete({
                                modalText: action.modalText(name),
                                action: () => action.func(itemId),
                            });
                        }}
                        key={`${action.name}-${itemId}`}
                    >
                        {action.headerName}
                    </div>
                ) : (
                    ''
                );
            })}
            {fileUrl && (
                <div
                    className="archiveItemMenu__action"
                    onClick={() => loader.download(fileUrl)}
                    key={`download-${itemId}`}
                >
                    Скачать
                </div>
            )}
        </div>
    );
};

export default ArchiveItemMenu;
