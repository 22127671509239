import React, { useState } from 'react';
import Cell from '../Cell/Cell';
import ArchiveItemMenuButton from '@components/photoArchive/common/ArchiveItemMenuButton/ArchiveItemMenuButton';
import { restoreName } from '@components/photoArchive/common/helpers/helpers';
import Checkbox from '../Checkbox/Checkbox';
import { ModalParams } from '@components/photoArchive/common/ArchiveItemMenu/ArchiveItemMenu';

import styles from './Row.module.scss';
import checkmark from '@images/check-mark_white.svg';

type RowProps = {
    name: string;
    date: number;
    id: string;
    itemContent?: string | number;
    preview: string;
    full: string;
    onClick: (id: string) => void;
    markRow: (id: string) => void;
    isChecked: boolean;
    onDelete: (params: ModalParams) => void;
};

const FileRow = ({
    name,
    date,
    id,
    itemContent,
    preview,
    full,
    onClick,
    markRow,
    isChecked,
    onDelete,
}: RowProps): JSX.Element => {
    const [isVisible, setVisible] = useState(false);
    const contentName = itemContent ?? '';

    return (
        <div
            className={styles.Row}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            onClick={() => onClick(id)}
        >
            <div className={styles.Check}>
                <Checkbox
                    isChecked={isChecked}
                    isShown={isVisible}
                    markRow={() => markRow(id)}
                />
            </div>
            <div
                className={`${styles.LeftBorder} ${
                    isChecked ? styles.Marked : ''
                }`}
            ></div>
            <div className={`${styles.Name} ${isChecked ? styles.Marked : ''}`}>
                <Cell
                    isFocused={isChecked || isVisible}
                    isEditable={false}
                    isNameCell={true}
                    value={restoreName(name)}
                    type="FILE"
                    preview={preview}
                />
            </div>
            <div className={`${styles.Date} ${isChecked ? styles.Marked : ''}`}>
                <Cell
                    isFocused={isChecked || isVisible}
                    isEditable={false}
                    isNameCell={false}
                    value={new Date(date)
                        .toISOString()
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('.')}
                />
            </div>
            <div
                className={`${styles.Files} ${isChecked ? styles.Marked : ''}`}
            >
                <Cell
                    isFocused={isChecked || isVisible}
                    isEditable={false}
                    isNameCell={false}
                    value={contentName}
                />
            </div>
            <div
                className={`${styles.Other} ${isChecked ? styles.Marked : ''}`}
            ></div>
            <div
                className={`${styles.RightBorder} ${
                    isChecked ? styles.Marked : ''
                }`}
            >
                <div className={styles.ButtonWrapper}>
                    <ArchiveItemMenuButton
                        isVisible={isVisible}
                        itemId={id}
                        type="FILE"
                        name={name}
                        fileUrl={full}
                        onDelete={onDelete}
                    />
                </div>
            </div>
        </div>
    );
};

export default FileRow;
