import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { restoreName } from '../helpers/helpers';
import styles from './PhotoViewModal.module.scss';

type PhotoViewProps = {
    src: string;
    title: string;
    onSwitch: (ind: number) => void;
    onClose: () => void;
    hasPrev: boolean;
    hasNext: boolean;
};

const PhotoViewModal = ({
    src,
    title,
    onSwitch,
    onClose,
    hasPrev,
    hasNext,
}: PhotoViewProps): JSX.Element => {
    const keyPressListener = (e: Event) => {
        const key = (e as unknown as React.KeyboardEvent).key;
        if (key === 'ArrowLeft') {
            onSwitch(-1);
        } else if (key === 'ArrowRight') {
            onSwitch(1);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyPressListener);
        return () => {
            document.removeEventListener('keydown', keyPressListener);
        };
    }, [src]);

    return ReactDOM.createPortal(
        <div className={styles.BackBlur} onClick={onClose}>
            <div className={styles.Content}>
                <div
                    className={`${styles.Previous} ${
                        hasPrev ? styles.Active : ''
                    }`}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        hasPrev && onSwitch(-1);
                    }}
                ></div>
                <div
                    className={styles.PictureWrapper}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                    <div className={styles.Close} onClick={onClose}></div>
                    <img src={src} alt="" className={styles.Picture} />
                    <h2 className={styles.Description}>{restoreName(title)}</h2>
                </div>
                <div
                    className={`${styles.Next} ${hasNext ? styles.Active : ''}`}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        hasNext && onSwitch(1);
                    }}
                ></div>
            </div>
        </div>,
        document.body
    );
};

export default PhotoViewModal;
