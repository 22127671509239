import { GridColDef } from '@mui/x-data-grid';
import { IPatient } from '@interfaces/patient.interface';
import { constructLocalDate } from '@utils/constructLocalDate';
import PresentationsTableStatusColumn from '@components/presentationstable/common/PresentationsTableStatusColumn';
import PresentationsTableTitleColumn from '@components/presentationstable/common/PresentationsTableTitleColumn';
import PresentationsTableActionsColumn from '@components/presentationstable/common/PresentationsTableActionsColumn';
import PresentationsTablePatientColumn from '@components/presentationstable/common/PresentationsTablePatientColumn';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { updatePresentationTitle } from '@feature/presentations.slice';
import PresentationsTablePhotoCollectionColumn from '@components/presentationstable/common/PresentationsTablePhotoCollectionColumn';
import { useHistory } from 'react-router';

export function PresentationsTableColumns(isGlobal: boolean): GridColDef[] {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const elements: GridColDef[] = [
        {
            field: 'state',
            headerName: 'Статус',
            width: 130,
            renderCell: (params) => PresentationsTableStatusColumn(params),
        },
        {
            field: 'name',
            headerName: 'Название',
            disableColumnMenu: true,
            flex: 2,
            renderCell: (params) =>
                PresentationsTableTitleColumn({
                    ...params,
                    changeState: (id, title) =>
                        dispatch(updatePresentationTitle({ id, name: title })),
                }),
        },
        {
            field: 'pages',
            headerName: '#',
            disableColumnMenu: true,
            width: 48,
            align: 'center',
            valueGetter: (params) => params.row.pages,
        },
        {
            field: 'date',
            headerName: 'Дата',
            type: 'dateTime',
            disableColumnMenu: true,
            flex: 1,
            align: 'left',
            valueFormatter: (params) =>
                params.value ? constructLocalDate(+params.value) : '',
        },
        {
            field: 'PhotoCollection',
            headerName: 'Фотоколлекция',
            disableColumnMenu: true,
            flex: 2,
            renderCell: (params) =>
                PresentationsTablePhotoCollectionColumn({
                    ...params,
                    dispatch,
                    goTo: (route: string) => history.push(route),
                }),
        },
        {
            field: 'change',
            headerName: 'Действия',
            headerAlign: 'right',
            align: 'right',
            sortable: false,
            flex: 2,
            disableColumnMenu: true,
            renderCell: (params) => PresentationsTableActionsColumn(params),
        },
    ];

    if (isGlobal) {
        const patientRow: GridColDef = {
            field: 'patient',
            headerName: 'Пациент',
            disableColumnMenu: true,
            renderCell: (params) => PresentationsTablePatientColumn(params),
            flex: 1,
            sortComparator: (v1: IPatient, v2: IPatient): number => {
                return `${v1.surname} ${v1.name}`.localeCompare(
                    `${v2.surname} ${v2.name}`
                );
            },
        };

        elements.splice(1, 0, patientRow);
    }

    return elements;
}
