import React, { useState } from 'react';

import style from './Plan.module.scss';
import ai from '@assets/images/ai.svg';

type PlanProps = {
    title: string;
    maxPrice: number;
    description: string;
    price: string;
    features: { title: string; ai: boolean }[];
    isActive: boolean;
    isButtonDisabled: boolean;
    buttonTitle: string;
    onTap: () => void;
};

export function Plan({
    title,
    description,
    maxPrice,
    price,
    features,
    isActive,
    isButtonDisabled,
    buttonTitle,
    onTap,
}: PlanProps): JSX.Element {
    return (
        <div className={style.Container}>
            <div
                className={
                    isActive ? style.PlanContainerActive : style.PlanContainer
                }
            >
                <div className={style.PlanTitleContainer}>
                    <h2>{title}</h2>{' '}
                    {price == '1095' && <div className={style.Disc}>-50 %</div>}
                    {isActive && <div className={style.PlanFree}>Активный</div>}
                </div>
                <p className={style.PlanDescription}>{description}</p>
                <div className={style.PlanPriceContainer}>
                    {price == '1095' && (
                        <div className={style.DiscPrice}>2190 ₽</div>
                    )}
                    <h2
                        className={style.PlanPrice}
                        style={price == '1095' ? { color: 'red' } : {}}
                    >
                        {price}
                    </h2>
                    <h4 style={price == '1095' ? { color: 'red' } : {}}>₽</h4>
                    <div className={style.PlanPriceDetails}>
                        {Number(price) > 0 && Number(price) != maxPrice && (
                            <>
                                <s>{maxPrice}</s>
                            </>
                        )}
                        <p>в месяц</p>
                    </div>
                </div>
                <div
                    className={
                        isButtonDisabled
                            ? style.PlanBuyButton
                            : style.PlanBuyButtonActive
                    }
                    onClick={() => onTap()}
                >
                    {buttonTitle}
                </div>
            </div>
            <div className={style.PlanFeatureContainer}>
                <h5>Включает</h5>
                {features.map((feature, index) => (
                    <div key={index} className={style.PlanFeatureRow}>
                        {feature.ai ? (
                            <img
                                style={{ paddingLeft: 3, paddingRight: 3 }}
                                src={ai}
                            />
                        ) : (
                            <div className={style.PlanFeatureImg} />
                        )}
                        <p>{feature.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
