import React from 'react';
import { setPresentationState } from '@utils/presentation';
import { changePresentationStatus } from '@feature/presentations.slice';
import { StatusCell } from '@components/presentationstable/common/StatusCell';
import { GridCellParams } from '@mui/x-data-grid';
import { useAppDispatch } from '@hooks/useAppDispatch';

const PresentationsTableStatusColumn = (
    params: GridCellParams
): JSX.Element => {
    const dispatch = useAppDispatch();
    const changeStatus = (newStatus: 'inProgress' | 'Done') => {
        setPresentationState(params.row.id, newStatus).then(() => {
            dispatch(
                changePresentationStatus({
                    id: params.row.id,
                    newState: newStatus,
                })
            );
        });
    };
    return StatusCell(params.row.state, changeStatus);
};

export default PresentationsTableStatusColumn;
