import * as z from 'zod';

const name_regex = new RegExp(/^.*(?=.{2,})[ёЁа-яА-Я\-]*$/);

const basic_schema = z.object({
    email: z
        .string()
        .regex(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            'Неверный адрес почты'
        )
        .max(30, 'Пароль слишком длинный')
        .nonempty('Это поле обязательно для заполнения'),
    name: z
        .string()
        .regex(
            name_regex,
            'Поле может содержать дефис, заглавные и строчные русские буквы'
        )
        .min(2, 'Минимальная длина 2')
        .max(20, 'Максимальная длина 20')
        .nonempty('Это поле обязательно для заполнения'),
    surname: z
        .string()
        .regex(
            name_regex,
            'Поле может содержать дефис, заглавные и строчные русские буквы'
        )
        .min(2, 'Минимальная длина 2')
        .max(20, 'Максимальная длина 20')
        .nonempty('Это поле обязательно для заполнения'),
    middleName: z
        .string()
        .refine(
            (data: any) => data.length <= 0 || data.length >= 2,
            'Минимальная длина 2'
        )
        .refine((data: any) => data.length <= 20, 'Максимальная длина 20')
        .refine(
            (data: any) => data.length <= 0 || name_regex.test(data),
            'Поле может содержать дефис, заглавные и строчные русские буквы'
        ),
    company: z
        .string()
        .max(20, 'Максимальная длина 20')
        .refine(
            (data: any) => data.length <= 0 || data.length >= 2,
            'Минимальная длина 2'
        )
        .optional(),
    position: z
        .string()
        .max(20, 'Максимальная длина 20')
        .refine(
            (data: any) => data.length <= 0 || data.length >= 2,
            'Минимальная длина 2'
        )
        .optional(),
    degree: z
        .string()
        .max(20, 'Максимальная длина 20')
        .refine(
            (data: any) => data.length <= 0 || data.length >= 2,
            'Минимальная длина 2'
        )
        .optional(),
    promocode: z.string().optional(),
});

const full_schema = basic_schema
    .extend({
        currentPassword: z
            .string()
            .regex(
                /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                'Пароль должен содержать: буквы латинского алфавита, минимум 1 большой символ, одну цифру и быть длиной от 8 символов'
            )
            .nonempty('Это поле обязательно для заполнения'),
        newPassword: z
            .string()
            .regex(
                /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
                'Пароль должен содержать: буквы латинского алфавита, минимум 1 большой символ, одну цифру и быть длиной от 8 символов'
            )
            .max(30, 'Пароль слишком длинный')
            .nonempty('Это поле обязательно для заполнения'),

        newPasswordConfirm: z.string(),
    })
    .refine((data: any) => data.currentPassword !== data.newPassword, {
        message: 'Новый и старый пароли полностью совпадают',
        path: ['newPassword'],
    })
    .refine((data: any) => data.newPassword === data.newPasswordConfirm, {
        message: 'Пароли не совпадают',
        path: ['newPasswordConfirm'],
    });

export type ProfileFormDataType = {
    email: string;
    surname: string;
    name: string;
    middleName?: string;
    promocode?: string;
    company?: string;
    position?: string;
    degree?: string;
    currentPassword?: string;
    newPassword?: string;
    newPasswordConfirm?: string;
};

export function validator(is_editing: boolean) {
    return {
        schema: is_editing ? full_schema : basic_schema,
    };
}
