import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import './PatientInfoPresentationsPage.scss';

import { ROUTES } from '@constants/routes';
import PresentationsTable from '@components/presentationstable/common/PresentationsTable';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import { IPresentation } from '@interfaces/presentation.interface';
import { useAppSelector } from '@hooks/useAppSelector';
import { selectPresentations } from '@store/feature/presentations.slice';
import { selectSelectedPatient } from '@store/feature/patient.slice';
import { Alert } from '@components/common/alert/Alert';

const PatientInfoPresentationsPage = (): JSX.Element => {
    const selectedPatient = useAppSelector(selectSelectedPatient);
    const [presentations, setPresentations] = useState<IPresentation[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const allPresentation = useAppSelector(selectPresentations);

    useEffect(() => {
        if (selectedPatient) {
            setIsLoading(true);
            setPresentations(
                allPresentation.filter(
                    (presentation) =>
                        presentation.patient.id === selectedPatient.id
                )
            );
            setIsLoading(false);
        }
    }, [allPresentation, selectedPatient]);

    return (
        <div className="user_info_presentations_container">
            <Alert
                title="Презентации пациента"
                description="Список всех презентаций пациента. Просматривайте, меняйте статус и редактируйте."
                id="patient_pres"
            />
            <div className="user_info_presentations_title_container">
                <h2 className="user_info_presentations_title">Презентации</h2>

                <NavLink
                    to={ROUTES.PATIENTS.$(selectedPatient?.id).STORE.END}
                    style={{ textDecoration: 'none' }}
                    className="user_info_presentations_link"
                >
                    <button
                        className="user_info_presentations_btn"
                        id="add-pattern-button"
                    >
                        <div className="user_info_presentations_add_image" />
                    </button>
                </NavLink>
            </div>
            {isLoading ? (
                <CustomLoader />
            ) : (
                <PresentationsTable
                    presentations={presentations}
                    isLoading={isLoading}
                    isGlobal={false}
                    isRestricted={false}
                />
            )}
        </div>
    );
};

export default PatientInfoPresentationsPage;
