import React, { useEffect, useRef, useState } from 'react';
import ArchiveItemMenuIcon from '../../../../assets/images/action_menu.svg';
import ArchiveItemMenu from '../ArchiveItemMenu/ArchiveItemMenu';
import { ModalParams } from '../ArchiveItemMenu/ArchiveItemMenu';
import './ArchiveItemMenuButton.scss';

type ButtonProps = {
    itemId: string;
    isVisible: boolean;
    type: 'FILE' | 'FOLDER';
    name: string;
    fileUrl?: string;
    onDelete: (params: ModalParams) => void;
};

const ArchiveItemMenuButton = ({
    itemId,
    isVisible,
    type,
    name,
    fileUrl,
    onDelete,
}: ButtonProps): JSX.Element => {
    const [menuIsShown, setMenuIsShown] = useState(false);
    useEffect(() => {
        if (!isVisible) setMenuIsShown(false);
    });

    const buttonRef = useRef<HTMLImageElement>(null);

    return (
        <div
            className="ArchiveItemMenuButton"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMenuIsShown((prev) => !prev);
            }}
        >
            <img src={ArchiveItemMenuIcon} alt="" ref={buttonRef} />
            {menuIsShown && isVisible ? (
                <ArchiveItemMenu
                    itemId={itemId}
                    position={{
                        top: buttonRef.current ? buttonRef.current.y + 35 : 0,
                        left: buttonRef.current ? buttonRef.current.x - 135 : 0,
                    }}
                    type={type}
                    name={name}
                    fileUrl={fileUrl}
                    onDelete={onDelete}
                />
            ) : null}
        </div>
    );
};

export default ArchiveItemMenuButton;
