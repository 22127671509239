import styles from './ResultPayment.module.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import { classes } from '@utils/classNames';

const ResultPayment = ({
    title,
    subtitle,
    buttonText,
    buttonLink,
    imageClass,
}: {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonLink: string;
    imageClass: string;
}) => (
    <div>
        <div className={styles.ImageContainer}>
            <div
                className={classes({
                    [styles.ImageError]: imageClass === 'error',
                    [styles.ImageSuccess]: imageClass === 'success',
                })}
            />
            <div>
                <h2>{title}</h2>
                <h4>{subtitle}</h4>
                <div className={styles.Button}>
                    <Link to={buttonLink}>
                        <p className={styles.ButtonTitle}>{buttonText}</p>
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default ResultPayment;
