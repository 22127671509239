import React from 'react';
import style from './Period.module.scss';
import { Subscription } from '@APIs/purchase';

type PlanProps = {
    active: string;
    maxPrice: number;
    periods: Subscription[];
    onTap: (period: Subscription) => void;
};

export function Period({
    active,
    maxPrice,
    periods,
    onTap,
}: PlanProps): JSX.Element {
    return (
        <div className={style.PeriodContainer}>
            {periods.map((period) => (
                <div
                    key={period._id}
                    onClick={() => onTap(period)}
                    className={
                        active == period._id ? style.ActivePeriod : style.Period
                    }
                >
                    {period.title}
                    {maxPrice != period.price && (
                        <div className={style.PeriodPercent}>
                            -
                            {(
                                100 -
                                (Number(period.price) / maxPrice) * 100
                            ).toFixed(0)}{' '}
                            %
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
