import {
    GridColDef,
    GridRenderCellParams,
    GridSortCellParams,
} from '@mui/x-data-grid';
import differenceInYears from 'date-fns/differenceInYears';
import { PatientAvatarCell } from './PatientAvatarCell';
import styles from '@components/presentationstable/common/PresentationsTable.module.scss';
import React, { useState } from 'react';
import { Modal } from '@components/common/ModalWindow/ModalWindow';
import { doctorAPIs } from '@APIs/doctor.apis';
import emailIcon from '@images/emailIcon.svg';
import { ageToStr } from '@utils/utils';

export const PatientsTableColumns: GridColDef[] = [
    {
        field: 'patient',
        headerName: 'Пациент',
        hideable: false,
        renderCell: function Patient(
            params: GridRenderCellParams
        ): JSX.Element {
            return (
                <div className={styles.table_row_patient}>
                    {PatientAvatarCell({
                        id: params.row.data.id,
                        avatar: params.row.data.avatar,
                        name: params.row.data.name,
                    })}
                    <div className={styles.table_row_patient_title}>
                        {params.row.data.patient}
                    </div>
                </div>
            );
        },
        flex: 1,
        valueGetter: (params: GridRenderCellParams) => params.row.data.patient,
        sortComparator: (v1: string, v2: string): number => {
            return v1.localeCompare(v2);
        },
    },
    {
        field: 'email',
        headerName: 'Почта',
        hideable: false,
        flex: 0.75,
        renderCell: function PatientEmail(
            params: GridRenderCellParams
        ): JSX.Element {
            return (
                <div className={styles.table_row_patient_title}>
                    {params.row.data.email || (
                        <img src={emailIcon} alt={'email icon'} />
                    )}
                </div>
            );
        },
        valueGetter: (params: GridRenderCellParams) => params.row.data.email,
        sortComparator: (
            v1: string,
            v2: string,
            param: GridSortCellParams
        ): number => {
            const order = param.api.state.sorting.sortModel[0].sort;
            if (order === 'asc') {
                if (!v1) return 1;
                if (!v2) return -1;
                if (v1[0] >= '0' && v1[0] <= '9') return 1;
                if (v2[0] >= '0' && v2[0] <= '9') return -1;
            }
            if (order === 'desc') {
                if (!v1) return -1;
                if (!v2) return 1;
                if (v1[0] >= '0' && v1[0] <= '9') return -1;
                if (v2[0] >= '0' && v2[0] <= '9') return 1;
            }
            return v1.localeCompare(v2);
        },
    },
    {
        field: 'dateOfBirth',
        headerName: 'Возраст',
        hideable: false,
        flex: 0.25,
        renderCell: function PatientBrithDay(
            params: GridRenderCellParams
        ): JSX.Element {
            return (
                <>
                    {ageToStr(
                        differenceInYears(
                            new Date(),
                            new Date(params.row.data.dateOfBirth)
                        )
                    )}
                </>
            );
        },
        valueGetter: (params: GridRenderCellParams): number => {
            return differenceInYears(
                new Date(),
                new Date(params.row.data.dateOfBirth)
            );
        },
        sortComparator: (v1: number, v2: number): number => {
            return v1 - v2;
        },
    },
    {
        field: 'action',
        headerName: 'Действия',
        hideable: false,
        flex: 0.25,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        disableColumnMenu: true,
        renderCell: function PatientAction(
            params: GridRenderCellParams
        ): JSX.Element {
            const [isModalOpen, setOpenModal] = useState(false);
            return (
                <div className="cell-button">
                    <div className="cell-button__container">
                        <p
                            className="cell-button__label"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenModal(true);
                            }}
                        >
                            Удалить
                        </p>
                    </div>
                    <Modal
                        message={`Вы действительно хотите удалить пациента ${params.row.data.surname} ${params.row.data.name}?`}
                        isOpen={isModalOpen}
                        onClose={() => setOpenModal(false)}
                        btnLabel="Удалить"
                        btnAction={() =>
                            doctorAPIs
                                .deletePatient(params.row.data.id)
                                .then(() =>
                                    params.row.deleteFunction(
                                        params.row.data.id
                                    )
                                )
                        }
                    />
                </div>
            );
        },
    },
];
