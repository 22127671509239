import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { removeFolderElement } from '@store/feature/folder.slice';
import { store } from '@store/index';

export function deleteFile(id: string): void {
    PhotoArchiveApis.deleteFile(id).then((res) => {
        if (res.status === 200) {
            store.dispatch(removeFolderElement({ id: id }));
        }
    });
}

export function deleteFiles(ids: string[]): void {
    ids.map((id) => deleteFile(id));
}

export function deleteFolder(id: string): void {
    PhotoArchiveApis.deleteFolder(id).then((res) => {
        if (res.status === 200) {
            store.dispatch(removeFolderElement({ id: id }));
        }
    });
}

const actions = [
    {
        name: 'delete',
        headerName: 'Удалить',
        types: ['FILE'],
        modalText: (name?: string): string => {
            return `Вы действительно хотите удалить ${decodeURI(name ?? '')}?`;
        },
        func: (id: string): void => deleteFile(id),
    },
    {
        name: 'delete',
        headerName: 'Удалить',
        modalText: (name?: string): string => {
            return 'Вы действительно хотите удалить папку?';
        },
        types: ['FOLDER'],
        func: (id: string): void => deleteFolder(id),
    },
];

export default actions;
