import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TablePatientBtn } from '@components/common/table-component/table__patient-btn';
import { ROUTES } from '@constants/routes';
import { TableAvatarCellElem } from '@components/common/table-component/table__avatar-cell';
import { useHistory } from 'react-router';

const PresentationsTablePatientColumn = (params: GridRenderCellParams) => {
    const history = useHistory();
    const patientID = params.row.patient.id;

    return (
        <TablePatientBtn
            onClick={() => {
                history.push(ROUTES.PATIENTS.$(patientID).PRESENTATIONS.END);
            }}
        >
            <>
                {TableAvatarCellElem({
                    id: params.row.patient.id,
                    avatar: params.row.patient.avatar,
                    name: params.row.patient.name,
                })}

                <div className="table-row__patient-title">
                    {params.row.patient.surname + ' ' + params.row.patient.name}
                </div>
            </>
        </TablePatientBtn>
    );
};

export default PresentationsTablePatientColumn;
