import React from 'react';
import styles from './PresentationsTable.module.scss';
import { DataGrid, GridFooter, ruRU } from '@mui/x-data-grid';
import { PresentationsTableColumns } from './PresentationsTableColumns';
import { makeStyles } from '@material-ui/core';
import { IPresentation } from '@interfaces/presentation.interface';
import TableSubscriptionAlert from '@components/tableSubscriptionAlert/TableSubscriptionAlert';
import '@styles/DataGrid.scss';
import { classes } from '@utils/classNames';
import { presentationsFake } from '@constants/tablesData';

const row =
    '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell';
const rowHover = '&.MuiDataGrid-root .MuiDataGrid-row:hover';
const cell =
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus';
const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
            overflow: 'inherit',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
            overflow: 'visible',
        },
        [cell]: {
            overflow: 'visible',
        },
        [row]: {
            overflow: 'visible',
            outline: 'none',
        },
        [rowHover]: {
            backgroundColor: '#b2b2b214',
        },
    },
});

export const PresentationsTable = ({
    presentations,
    isLoading = false,
    isGlobal = false,
    isRestricted,
}: {
    presentations: IPresentation[];
    isLoading?: boolean;
    isGlobal?: boolean;
    isRestricted: boolean;
}): JSX.Element => {
    const classesTable = useStyles();
    return (
        <div className={styles.table_container}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                }}
                components={{
                    Footer: () => {
                        return (
                            <div>
                                {isRestricted && isGlobal && (
                                    <TableSubscriptionAlert
                                        type={'presentations'}
                                    />
                                )}
                                <GridFooter />
                            </div>
                        );
                    },
                }}
                className={classes(classesTable.root, {
                    ['DataGridCustom']: isRestricted && isGlobal,
                })}
                columns={PresentationsTableColumns(isGlobal)}
                rows={
                    isRestricted && isGlobal
                        ? presentations.concat(presentationsFake)
                        : presentations
                }
                disableColumnMenu={true}
                loading={isLoading}
                hideFooterSelectedRowCount={true}
                disableSelectionOnClick={true}
                getRowId={(row) => row.id}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
};

export default PresentationsTable;
