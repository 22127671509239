import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Confetti from 'react-dom-confetti';

import { useAppDispatch } from '@hooks/useAppDispatch';
import { purchaseAPIs } from '@APIs/purchase';
import LoadingIndicator from '@components/patient/patientinfo/LoadingIndicator';
import styles from './PaymentPage.module.scss';

import { asyncLoadPatients } from '@feature/patients.slice';
import { asyncLoadPresentations } from '@feature/presentations.slice';
import { asyncLoadTrgList } from '@feature/trg.slice';
import { IPaymentStatus } from '@interfaces/Payment.interface';
import ResultPayment from '@components/payment/ResultPayment/ResultPayment';
import PaymentLink from './PaymentLink/PaymentLink';
import { ROUTES } from '@constants/routes';
import dateFormat from 'dateformat';

const formatDate = (date: number) => dateFormat(new Date(date), 'd mmmm yyyy');

const config = {
    angle: 90,
    spread: 360,
    startVelocity: 85,
    elementCount: 200,
    dragFriction: 0.12,
    duration: 4660,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const PaymentPage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState<IPaymentStatus | null>(null);
    const [link, setLink] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [price, setPrice] = useState<string | null>(null);
    const result = window.location.pathname.split('/').pop() ?? '';
    const location = useLocation();

    const request = () => {
        purchaseAPIs
            .status()
            .then((request) => {
                setStatus(request.data);
                if (!request.data.status) {
                    requestPay();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const requestPay = () => {
        purchaseAPIs
            .new(location.pathname.split('/').at(-1) ?? '')
            .then((response) => {
                setLink(response.data.url);
                setTitle(response.data.title);
                setPrice(response.data.price);
            });
    };

    useEffect(() => {
        request();
    }, []);

    useEffect(() => {
        if (result === 'success') {
            dispatch(asyncLoadPatients());
            dispatch(asyncLoadPresentations());
            dispatch(asyncLoadTrgList());
        }
    }, [result]);

    return (
        <div className={styles.Container}>
            <div className={styles.TitleContainer}>
                <div
                    onClick={() => history.back()}
                    className="user-info__header-back"
                />
                <p className={styles.Title}>Оплата подписки</p>
            </div>
            <LoadingIndicator isLoading={isLoading} />
            <Confetti
                active={result !== 'error' && (status?.status ?? false)}
                config={config}
            />
            <div className={styles.Page}>
                {isLoading ? (
                    <div>Получаем данные</div>
                ) : result === 'error' ? (
                    <ResultPayment
                        title="При оплате произошла ошибка!"
                        subtitle="Транзакция отклонена, повторите попытку."
                        buttonText="Повторить"
                        buttonLink={ROUTES.PAYMENT.END}
                        imageClass={'error'}
                    />
                ) : status?.status || result === 'success' ? (
                    <ResultPayment
                        title="Ваша подписка оплачена, спасибо!"
                        subtitle={`Активна до ${formatDate(status?.date ?? 0)}`}
                        buttonText="На главную"
                        buttonLink={ROUTES.PATIENTS.END}
                        imageClass={'success'}
                    />
                ) : (
                    <PaymentLink title={title} price={price} link={link} />
                )}
            </div>
        </div>
    );
};

export default PaymentPage;
