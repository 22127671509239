import React, { useEffect, useState } from 'react';

export const PatientAvatarCell = ({
    id,
    avatar,
    name,
}: {
    id?: string;
    avatar?: string;
    name?: string;
}): JSX.Element => {
    const [photoSrc, setPhotoSrc] = useState<string | undefined>('');

    useEffect(() => {
        (async function () {
            if (avatar && id) {
                setPhotoSrc(avatar);
            } else {
                setPhotoSrc('');
            }
        })();
    }, [id, avatar]);
    return photoSrc && photoSrc.length > 0 ? (
        <div
            style={{
                backgroundImage: `url(${photoSrc})`,
            }}
            className="user_list_table_row_image_loaded"
        />
    ) : (
        <div className="user_list_table_row_image">
            <p className="user-list-table-row-image-placeholder">
                {name?.charAt(0)}
            </p>
        </div>
    );
};
