import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './Cell.module.scss';
// import FolderIcon from '@images/folderIcon.svg';
import FolderIconDark from '@images/folderIcon_dark.svg';
// import SmartFolderIcon from '@images/smartFolderIcon.svg';
import SmartFolderIconDark from '@images/smartFolderIcon_dark.svg';
import { classes } from '@utils/classNames';
import { FolderIcon } from '@components/UI/FolderIcon';

type CellProps = {
    isEditable: boolean;
    value: string | number;
    isNameCell: boolean;
    type?: 'FILE' | 'FOLDER' | 'SMART';
    isFocused: boolean;
    editAction?: (arg: unknown) => void;
    preview?: string;
    key?: string;
};

const Cell = ({
    isEditable,
    value,
    isNameCell,
    type,
    isFocused,
    editAction,
    preview,
    key,
}: CellProps): JSX.Element => {
    const [readonly, setReadOnly] = useState(true);
    const [newValue, setValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputElement = inputRef.current;

    const enabledInput = () => {
        if (inputElement) {
            setReadOnly(false);
        }
    };

    useEffect(() => {
        if (!readonly && inputElement) {
            inputElement.focus();
        }
    });

    const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value.trim());
    };

    return (
        <div className={styles.Cell}>
            {isNameCell ? (
                ['FOLDER', 'SMART'].includes(type as string) ? (
                    <FolderIcon fill={'#FF0'} smart={type === 'SMART'} />
                ) : (
                    <img
                        className={`${styles.Icon} ${styles.FilePreview}`}
                        src={preview}
                        alt={String(value)}
                    />
                )
            ) : (
                ''
            )}
            {isEditable ? (
                <div
                    className={styles.InputWrapper}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onDoubleClick={enabledInput}
                >
                    <input
                        ref={inputRef}
                        className={classes(styles.InputReset)}
                        disabled={readonly}
                        value={newValue}
                        maxLength={255}
                        minLength={1}
                        onChange={onFolderNameChange}
                        onBlur={() => {
                            setReadOnly(true);
                            if (editAction) {
                                if (!newValue) {
                                    setValue(
                                        key ??
                                            (
                                                ['FOLDER', 'SMART'] as string[]
                                            ).includes(type ?? '')
                                            ? 'папка'
                                            : 'файл'
                                    );
                                    editAction(newValue);
                                } else editAction(newValue);
                            }
                        }}
                        onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                            if (e.key === 'Enter') {
                                setReadOnly(true);
                                if (editAction) editAction(newValue);
                                (e.target as HTMLInputElement).blur();
                            }
                        }}
                    />
                </div>
            ) : (
                <div
                    className={`${styles.NonEditable} ${
                        isNameCell ? styles.InputReset : ''
                    }`}
                >
                    {value}
                </div>
            )}
        </div>
    );
};

export default Cell;

interface Props {
    isEditable: boolean;
    value: string | number;
    isNameCell: boolean;
    editAction?: (arg: unknown) => void;
    key?: string;
}

interface FolderCellProps extends Props {
    type?: 'FOLDER' | 'SMART';
    style?: {
        bg_color: string;
    };
    onColorChange: (color: string) => void;
}

export function FolderCell({
    isEditable,
    value,
    isNameCell,
    type,
    editAction,
    style,
    onColorChange,
    key,
}: FolderCellProps): JSX.Element {
    const [readonly, setReadOnly] = useState(true);
    const [newValue, setValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputElement = inputRef.current;

    const enabledInput = () => {
        if (inputElement) {
            setReadOnly(false);
        }
    };

    useEffect(() => {
        if (!readonly && inputElement) {
            inputElement.focus();
        }
    });

    const onFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value.trim());
    };

    return (
        <div className={styles.Cell}>
            <FolderIconCell
                onColorChange={onColorChange}
                type={type}
                style={style}
                isNameCell={isNameCell}
            />
            {isEditable ? (
                <div
                    className={styles.InputWrapper}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onDoubleClick={enabledInput}
                >
                    <input
                        ref={inputRef}
                        className={classes(styles.InputReset)}
                        disabled={readonly}
                        value={newValue}
                        maxLength={255}
                        minLength={1}
                        onChange={onFolderNameChange}
                        onBlur={() => {
                            setReadOnly(true);
                            if (editAction) {
                                if (!newValue) {
                                    setValue(key ?? 'папка');
                                    editAction(newValue);
                                } else editAction(newValue);
                            }
                        }}
                        onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                            if (e.key === 'Enter') {
                                setReadOnly(true);
                                if (editAction) editAction(newValue);
                                (e.target as HTMLInputElement).blur();
                            }
                        }}
                    />
                </div>
            ) : (
                <div
                    className={classes(styles.NonEditable, {
                        [styles.InputReset]: isNameCell,
                    })}
                >
                    {typeof value === 'string' ? decodeURI(value) : value}
                </div>
            )}
        </div>
    );
}

function FolderIconCell({
    style,
    type,
    isNameCell,
    onColorChange,
}: {
    type?: 'FOLDER' | 'SMART';
    style?: {
        bg_color: string;
    };
    isNameCell: boolean;
    onColorChange: (color: string) => void;
}) {
    const [is_open, setIsOpen] = useState(false);

    const changeColor = (color: string) => {
        onColorChange(color);
        setIsOpen(false);
    };

    const collors = ['#EFF2F5', '#FFF5C2', '#CCFFD1', '#FFCCD5', '#CCDFFF'];

    return isNameCell ? (
        <div
            className={styles.folder}
            onContextMenu={(e) => {
                e.preventDefault();
                setIsOpen((prev) => !prev);
            }}
            onBlur={() => {
                setIsOpen(false);
            }}
        >
            <FolderIcon fill={style?.bg_color} smart={type === 'SMART'} />
            {is_open && (
                <div
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    className={styles.palettes}
                >
                    {collors.map((color) => (
                        <div
                            key={color}
                            className={styles.palettes__item}
                            style={{ background: color }}
                            onClick={() => changeColor(color)}
                        />
                    ))}
                </div>
            )}
        </div>
    ) : (
        <></>
    );
}
