import React from 'react';
import { IFileInLoad } from '@interfaces/collection.interface';
import styles from './FileRow.module.scss';
import Cancel from '@images/close_grey.svg';
import Done from '@images/done.svg';
import Error from '@images/loadError.svg';
import Repeat from '@images/repeat.svg';

const FileRow = ({ data }: { data: IFileInLoad }): JSX.Element => {
    const fillerStyles = {
        width: `${data.progress > 1 ? data.progress : 2}%`,
        backgroundColor: `${
            ['ERROR', 'CANCELLED'].includes(data.status) ? '#FF3366' : '#2A8BF2'
        }`,
    } as React.CSSProperties;
    return (
        <div className={styles.Wrapper}>
            <div className={styles.FileInfo}>
                <div className={styles.PreviewWrapper}>
                    <img
                        src={data.previewUrl}
                        alt=""
                        className={styles.Preview}
                    />
                    {['ERROR', 'CANCELLED'].includes(data.status) ? (
                        <img src={Error} className={styles.Done} />
                    ) : data.status === 'DONE' ? (
                        <img src={Done} className={styles.Done} />
                    ) : (
                        ''
                    )}
                </div>
                <div className={styles.Description}>
                    <div className={styles.Filename}>{data.name}</div>
                    <div className={styles.LoadingStatus}>
                        {['DONE', 'ERROR', 'CANCELLED'].includes(data.status)
                            ? data.name.substring(
                                  data.name.lastIndexOf('.') + 1
                              )
                            : data.progress > 0
                            ? `Загрузка ${data.progress}%`
                            : 'Загрузка...'}
                    </div>
                </div>
            </div>
            <div className={styles.Progress}>
                {data.status === 'DONE' ? (
                    <button className={styles.Open}>Открыть</button>
                ) : (
                    <>
                        <div className={styles.ProgressBar}>
                            <div
                                style={fillerStyles}
                                className={styles.ProgressFiller}
                            ></div>
                        </div>
                        {['ERROR', 'CANCELLED'].includes(data.status) ? (
                            <img
                                src={Repeat}
                                className={styles.Repeat}
                                onClick={() => data.reload()}
                            />
                        ) : (
                            <img
                                src={Cancel}
                                alt=""
                                className={styles.Cancel}
                                onClick={data.onCancel}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default FileRow;
