import {
    IFileInLoad,
    ILoadingProgress,
    LoadingStatus,
} from '@interfaces/collection.interface';
import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { uploadPhototoFolder } from '@utils/photo';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

export function loadFiles(
    patientId: string,
    folderId: string,
    files: FileList | File[],
    loadDoneCallback: () => void,
    loadStartCallback: (data: IFileInLoad[]) => void,
    onLoadProgressCallback: (progress: ILoadingProgress[]) => void
): void {
    const options = {
        maxSizeMB: 2,
        useWebWorker: true,
    };
    const loadingFiles = [] as IFileInLoad[];
    const progressFiles = [] as ILoadingProgress[];
    for (const file of files) {
        imageCompression(file, options).then((compressedFile) => {
            const objectUrl = URL.createObjectURL(file);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const id = Math.random();
            const progressData = {
                id: id,
                progress: 0,
                status: 'LOADING' as LoadingStatus,
            };
            const fileData = {
                name: file.name,
                previewUrl: objectUrl,
                progress: 0,
                cancelSource: source,
                id: id,
                status: 'LOADING' as LoadingStatus,
                reload: reload,
                onCancel: function () {
                    progressData.status = 'CANCELLED' as LoadingStatus;
                    source.cancel();
                },
            } as IFileInLoad;
            loadingFiles.push(fileData);
            progressFiles.push(progressData);
            loadStartCallback(loadingFiles);

            upload();

            function upload() {
                progressData.status = 'LOADING' as LoadingStatus;
                progressData.progress = 0;
                uploadPhototoFolder(
                    patientId,
                    folderId,
                    compressedFile,
                    fileData.cancelSource,
                    function (progressEvent: ProgressEvent) {
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        fileData.progress = progress;
                        progressData.progress = progress;
                        if (progress == 100) {
                            progressData.status = 'DONE' as LoadingStatus;
                            onLoadProgressCallback(progressFiles);
                        }
                        onLoadProgressCallback(progressFiles);
                    }
                )
                    .then(() => {
                        loadDoneCallback();
                        URL.revokeObjectURL(objectUrl);
                    })
                    .catch(() => {
                        if (progressData.status !== 'CANCELLED') {
                            progressData.status = 'ERROR' as LoadingStatus;
                        }
                        onLoadProgressCallback(progressFiles);
                    });
            }

            function reload() {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                fileData.cancelSource = source;
                upload();
            }
        });
    }
}

export function handleDrop(e: React.DragEvent<HTMLDivElement>): File[] {
    e.preventDefault();
    e.stopPropagation();
    const files: File[] = [];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files.item(i);

        if (
            file &&
            (file.type == 'image/png' ||
                file.type == 'image/jpg' ||
                file.type == 'image/jpeg')
        ) {
            files.push(file);
        }
    }

    return files;
}

export function cancelLoad(files: IFileInLoad[]): void {
    for (const file of files) {
        if (file.status === 'LOADING') file.onCancel();
    }
}

const TemplateNames: Record<string, string> = {
    FOLDER: 'simple',
};

export function addFolder(
    type: string,
    parentId: string,
    callback: () => void,
    isSubfolder: boolean
): void {
    PhotoArchiveApis.getTemplates().then((res) => {
        for (const item of res.data) {
            if (item.key === TemplateNames[type]) {
                const params = {
                    template_id: item.id,
                    ...(isSubfolder && { parent_id: parentId }),
                    ...(!isSubfolder && { patient_id: parentId }),
                };
                PhotoArchiveApis.createFolder(params).then((res) => {
                    if (res.status === 200) callback();
                });
            }
        }
    });
}

export function restoreName(name: string): string {
    let decodedName = decodeURIComponent(name);
    const fileTypeIndex = decodedName.lastIndexOf('.');
    if (fileTypeIndex !== -1) {
        decodedName = decodedName.substring(0, fileTypeIndex);
    }
    return decodedName;
}
