import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import styles from '@components/presentationstable/common/PresentationsTable.module.scss';
import { CellButtonComponent } from '@components/common/cell-button/cell-button';
import { ROUTES } from '@constants/routes';
import { toast } from 'react-toastify';
import { doctorAPIs } from '@APIs/doctor.apis';

const PresentationsTableActionsColumn = (params: GridRenderCellParams) => {
    const getOrCreateLink = async (presentation: string, patient: string) => {
        return doctorAPIs.getOrCreateLink(presentation, patient);
    };
    const copyToClipboard = async (presentation: string, patient: string) => {
        const link = getOrCreateLink(presentation, patient);
        await toast.promise(link, {
            pending: 'Создаем ссылку',
            success: 'Успешно скопировано в буфер обмена',
            error: 'Promise rejected 🤯',
        });

        await navigator.clipboard.writeText(
            process.env.REACT_PUBLIC_APP_URL +
                '/presentations/' +
                (
                    await link
                ).data.link
        );
    };

    const openLink = async (presentation: string, patient: string) => {
        const link = getOrCreateLink(presentation, patient);
        const url = await link;

        window.open(
            process.env.REACT_PUBLIC_APP_URL +
                '/presentations/' +
                url.data.link,
            '_blank'
        );
    };

    return (
        <div className={styles.actions}>
            <div
                className="cell-button"
                onClick={() =>
                    copyToClipboard(params?.row.id, params?.row.patient.id)
                }
            >
                <div className="cell-button__container">
                    <div className="cell-button__label">
                        <div className={styles.copy_img} />
                    </div>
                </div>
            </div>
            <CellButtonComponent
                to={ROUTES.PRESENTATIONS.$(params?.row.id).END}
            >
                Изменить
            </CellButtonComponent>
            <div
                className="cell-button"
                onClick={() => openLink(params?.row.id, params?.row.patient.id)}
            >
                <div className="cell-button__container">
                    <p className="cell-button__label">Открыть</p>
                </div>
            </div>
        </div>
    );
};

export default PresentationsTableActionsColumn;
