import React, { useRef, useState } from 'react';

import styles from './Dropdown.module.scss';
import dropdown from '@images/dropdown_active.svg';

type DropdownProps = {
    title: string;
    options: number[];
    defaultOption: number;
    setOption: (option: number) => void;
};

const Dropdown = ({
    title,
    options,
    defaultOption,
}: DropdownProps): JSX.Element => {
    const [option, setOption] = useState(options[defaultOption]);
    const [showList, toggleShow] = useState(false);

    let menuRight = 0;
    let menuTop = 0;

    const menuRef = useRef<HTMLDivElement>(null);
    const buttonElement = menuRef.current;
    if (buttonElement) {
        menuRight = buttonElement.clientWidth - 60;
        menuTop = buttonElement.clientHeight + 8;
    }
    return (
        <>
            <div className={styles.Wrapper} ref={menuRef}>
                <span className={styles.Title}>{title}:</span>
                <div
                    className={styles.Picker}
                    onClick={() => {
                        toggleShow(!showList);
                    }}
                >
                    <span className={styles.OptionPicked}>{option}</span>
                    <img
                        className={`${styles.DropdownIcon} ${
                            showList ? styles.Shown : ''
                        }`}
                        src={dropdown}
                    />
                </div>
                {showList ? (
                    <div
                        className={styles.OptionList}
                        style={{ left: menuRight, bottom: menuTop }}
                    >
                        {options.map((item, ind) => {
                            return (
                                <li
                                    className={styles.Option}
                                    key={`option_${ind}`}
                                    onClick={() => {
                                        setOption(item);
                                        // setDropdownOption(item);
                                        toggleShow(false);
                                    }}
                                >
                                    {item}
                                </li>
                            );
                        })}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default Dropdown;
