import React from 'react';
import styles from './ProfileHeader.module.scss';
import Button from '@components/common/NEWDESIGN/Button/Button';

const ProfileHeader = ({
    isLoading,
    handleSubmit,
}: {
    isLoading: boolean;
    handleSubmit: React.MouseEventHandler<HTMLButtonElement> | undefined;
}): JSX.Element => {
    return (
        <div className={styles.header}>
            <div className={styles.header__content}>
                <p className={styles.header__title}>Профиль</p>
                <Button
                    text={'Сохранить'}
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    size={'SMALL'}
                    padding={'12px 30px'}
                />
            </div>
            <hr className={styles.header__line} />
        </div>
    );
};

export default ProfileHeader;
