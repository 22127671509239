import React from 'react';
import AddFolderMenuItem from './addFolderMenuItem/addFolderMenuItem';
import { addFolder } from '../helpers/helpers';
import { loadFiles } from '../helpers/helpers';
import {
    IFileInLoad,
    ILoadingProgress,
} from '@interfaces/collection.interface';

import './addFolderMenu.scss';

type AddMenuProps = {
    patientId: string;
    position: { left: number; top: number };
    isInFolder: boolean;
    folderId?: string;
    onElementAddition: () => void;
    onLoadFile: (data: IFileInLoad[]) => void;
    onLoadProgress: (progress: ILoadingProgress[]) => void;
};

const AddFolderMenu = ({
    patientId,
    position,
    isInFolder,
    folderId,
    onElementAddition,
    onLoadFile,
    onLoadProgress,
}: AddMenuProps): JSX.Element => {
    function onOpenFileManager() {
        if (folderId) {
            const input = document.createElement('input');
            input.setAttribute('multiple', 'true');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/png, image/jpeg');
            input.addEventListener('change', (e) => {
                const target = e.target as HTMLInputElement;

                if (target?.files) {
                    loadFiles(
                        patientId,
                        folderId,
                        target.files,
                        onElementAddition,
                        onLoadFile,
                        onLoadProgress
                    );
                }
            });
            input.click();
        }
    }
    return (
        <div className="addFolderMenu" style={position}>
            <AddFolderMenuItem
                name="Папку"
                clickFunction={() => {
                    addFolder(
                        'FOLDER',
                        isInFolder && folderId ? folderId : patientId,
                        onElementAddition,
                        isInFolder
                    );
                }}
            />
            {isInFolder && folderId && (
                <>
                    <AddFolderMenuItem
                        name="Файлы"
                        clickFunction={onOpenFileManager}
                    />
                </>
            )}
        </div>
    );
};

export default AddFolderMenu;

// TODO связать кнопку добавления и меню, сделать стили для этих объектов, создать REDUX модуль для взаимодействия с папками и функциями меню, создать route для магических папок, организовать работу в магических папках
